import SearchInput from "../../../../organizers/ui/elements/searchInput";
import React from "react";
import Select from "../../../../organizers/ui/elements/select";
import _ from "lodash";
import { SportType } from "../../../../core/domain/enums/SportType";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { selectFilterByName, selectSortByDate } from "../../../store/catalog/selectors";
import { setFilterByName, setSortByDate } from "../../../store/catalog/catalog";

function Header() {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>()
  const sortByDate = useSelector(selectSortByDate)
  const filterByName = useSelector(selectFilterByName)
  
  const searchInput = {
    headerText: 'Поиск по тексту:',
    value: filterByName,
    placeholder: 'Название шаблона',
    onChange: (value: string) => {
      dispatch(setFilterByName(value))
    },
  }
  
  const sortByDateOptions = [
    { value: 'none', label: 'Все' },
    { value: 'desc', label: 'Новые' },
    { value: 'asc', label: 'Старые' },
  ]
  const sortByData = {
    headerText: 'Сортировать по дате:',
    options: sortByDateOptions,
    value: sortByDateOptions.find(({ value }) => value === sortByDate) ?? sortByDateOptions[ 0 ],
    onChange: (value: { label: string | number | JSX.Element, value: string } | undefined) => {
      const val = value?.value
      if (val) {
        dispatch(setSortByDate(val as 'asc' | 'desc' | 'none'))
      }
    },
  }
  
  const sportTypes = _.keys(SportType).map((key) => ({ value: key, label: _.capitalize(t('sportType.' + key)) }))
  sportTypes.unshift({ value: 'all', label: 'Все' })
  const filterBySport = {
    headerText: 'Тип спорта:',
    options: sportTypes,
    value: sportTypes.find(({ value }) => value === '') ?? sportTypes[ 0 ],
    onChange: (value: { label: string | number | JSX.Element, value: string } | undefined) => {
      const val = value?.value
      // dispatch(setFilterBySportType(val as SportType))
    },
  }
  
  return (
    <div className={"d-flex"} style={{ gap: 20, marginTop: 18, paddingInline: 24 }}>
      <SearchInput style={{ width: 451 }} {...searchInput} />
      <Select style={{ width: 151 }} {...sortByData}/>
      <Select disable={true} style={{ width: 200 }} {...filterBySport}/>
    </div>
  );
}

export default Header;
