import { format, isAfter, isSameDay, startOfDay } from 'date-fns';
import { Game, GameTeam } from 'modules/games/domain/interfaces/Game';
import { Team } from 'modules/teams/domain/interfaces/Team';
import CupIcon from 'modules/ud-ui/components/icon/cup-icon';
import EditIcon from 'modules/ud-ui/components/icon/edit-icon';
import TrashIcon from 'modules/ud-ui/components/icon/trash-icon';
import TeamLogo from 'modules/ud-ui/components/team-logo';
import UDText from 'modules/ud-ui/components/text';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { useReactToPrint } from 'react-to-print';
import { AppDispatch } from '../../../../../store/store';
import { useOrganizer } from '../../../../organizer/ui/hooks/useOrganizer';
import UDButton from '../../../../ud-ui/components/button';
import UDIcon from '../../../../ud-ui/components/icon';
import NotepadPenIcon from '../../../../ud-ui/components/icon/notepad-pen-icon';
import { useModal } from '../../../../ud-ui/components/modal/useModal';
import { GameDetails } from '../../../domain/interfaces/GameDetails';
import { fetchGameDetails } from '../../../store/actions';
import { MatchProtocolToPrint } from './matchProtocolToPrint';
import * as S from './styles';
import { DivProps } from "../../../../../typings";

type GameTeamProps = {
  team: GameTeam | null;
  className?: string;
};

const GameTeamInfo = ({ team, className }: GameTeamProps) => {
  const isLeft = className === 'left';
  const iconFirst = !isLeft;
  const hasScore = !!team && team.score !== null;
  const infoNode = (<TeamInfo team={team} iconFirst={iconFirst}/>);
  
  return (
    <S.TeamContainer className={className}>
      {isLeft && infoNode}
      {hasScore && (
        <S.GameTeamScore className="score">
          {team?.score}
        </S.GameTeamScore>
      )}
      {!isLeft && infoNode}
    </S.TeamContainer>
  );
};

type TeamInfoProps = {
  team: Team | null;
  iconFirst?: boolean;
};

const defaultTeamInfoProps = {
  iconFirst: true,
};

const TeamInfo = (props: TeamInfoProps) => {
  const allProps = {
    ...defaultTeamInfoProps,
    ...props,
  };
  
  const {
    iconFirst,
    team,
  } = allProps;
  
  const teamName = team?.name || 'Неизвестно';
  const teamLogo = team?.logo?.url;
  
  if (iconFirst) {
    return (
      <>
        <TeamLogo
          className="mr-2"
          url={teamLogo}
        />
        <UDText type="subhead">{teamName}</UDText>
      </>
    );
  }
  
  return (
    <>
      <UDText type="subhead">{teamName}</UDText>
      <TeamLogo
        className="ml-2"
        url={teamLogo}
      />
    </>
  );
};

const TeamsDelimiter = () => (
  <S.TeamsDelimiterContainer>
    <S.TeamsDelimiter/>
  </S.TeamsDelimiterContainer>
);

type GamesComponentsGameCardProps = {
  game: Game;
  onEditClick?: (game: Game) => void;
  onShowTemplatesClick?: (gameId: number) => void;
  onShowResultClick?: (gameId: number) => void;
  onDeleteClick?: (gameId: number) => void;
} & Omit<DivProps, 'children'>;

const GamesComponentsGameCard = (props: GamesComponentsGameCardProps) => {
  const {
    game,
    onEditClick,
    onShowResultClick,
    onShowTemplatesClick,
    onDeleteClick,
    ...containerProps
  } = props;
  
  const { info } = useOrganizer();
  
  
  const {
    open: openDeleteModal,
    close: closeDeleteModal,
    Modal: ConfirmDeleteModal,
  } = useModal({ defaultShow: false });
  
  const dispatch = useDispatch<AppDispatch>();
  const printRef = useRef(null);
  
  const [isPrintLoading, setPrintLoading] = useState<boolean>(false);
  const [matchDetails, setMatchDetails] = useState<GameDetails | null>(null);
  const { info: organizer } = useOrganizer()
  
  const isDev = process.env.REACT_APP_ENVIRONMENT === 'development';
  const showTemplatesBtn = useMemo(() => isDev ? true : organizer?.availableFeatures.OBS_INTEGRATION, [isDev, organizer?.availableFeatures.OBS_INTEGRATION]);
  
  const showEditResultsBtn = useMemo(() => {
    const now = new Date();
    
    return (
      game.hasResults
      || isSameDay(game.date, now)
      || isAfter(startOfDay(now), game.date)
    );
  }, [game]);
  
  const onShowResulClicked = useCallback(() => {
    if (onShowResultClick) {
      onShowResultClick(game.id);
    }
  }, [game, onShowResultClick]);
  
  const onShowTemplatesClicked = useCallback(() => {
    if (onShowTemplatesClick) {
      onShowTemplatesClick(game.id);
    }
  }, [game, onShowTemplatesClick]);
  
  const deleteClicked = useCallback(() => {
    openDeleteModal()
  }, [openDeleteModal]);
  
  const cancelDeleteEvent = useCallback(() => {
    closeDeleteModal();
  }, [closeDeleteModal]);
  
  const confirmDeleteEvent = useCallback(() => {
    if (onDeleteClick) {
      onDeleteClick(game.id);
    }
    closeDeleteModal();
  }, [game, onDeleteClick, closeDeleteModal]);
  
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: 'Протокол матча',
    onBeforeGetContent: async () => {
      setPrintLoading(true);
      
      const result = await dispatch(fetchGameDetails({ gameId: game.id }));
      if (result.payload) {
        setMatchDetails(result.payload as GameDetails);
      }
    },
    onAfterPrint: () => {
      setPrintLoading(false);
      setMatchDetails(null);
    },
  });
  
  const gameTeams = game.teams.filter(Boolean);
  
  return (
    <S.GameCardContainer {...containerProps}>
      <S.GameCardTime>
        {format(props.game.date, 'kk:mm')}
      </S.GameCardTime>
      
      <S.GameCardTeams>
        <GameTeamInfo
          className="left"
          team={gameTeams[ 0 ]}
        />
        <TeamsDelimiter/>
        <GameTeamInfo
          className="right"
          team={gameTeams[ 1 ]}
        />
      </S.GameCardTeams>
      
      <S.GameActions>
        {showTemplatesBtn && (
          <S.IconButton
            className="game-action-btn"
            onClick={onShowTemplatesClicked}
          >
            <UDIcon name={'monitor'}/>
          </S.IconButton>
        )}
        
        {handlePrint && (
          <S.IconButton
            className="game-action-btn"
            title="Протокол матча"
            onClick={handlePrint}
            disabled={isPrintLoading}
          >
            {isPrintLoading
              ? (<ClipLoader size={22}/>)
              : (<NotepadPenIcon width={22} height={22}/>)}
          </S.IconButton>
        )}
        
        {showEditResultsBtn && (
          <S.IconButton
            className="game-action-btn"
            onClick={onShowResulClicked}
          >
            <CupIcon width={14} height={16}/>
          </S.IconButton>
        )}
        
        {onEditClick && (
          <S.IconButton
            className="game-action-btn"
            onClick={() => onEditClick(game)}
          >
            <EditIcon width={16} height={16}/>
          </S.IconButton>
        )}
        
        {onDeleteClick && (
          <S.IconButton
            className="game-action-btn"
            onClick={deleteClicked}
          >
            <TrashIcon width={14} height={16}/>
          </S.IconButton>
        )}
      </S.GameActions>
      
      <ConfirmDeleteModal
        header={{ title: 'Подтвердить удаление', subtitle: 'Вы действительно хотите удалить матч?' }}
      >
        <div className="mt-10 d-flex justify-content-between">
          <UDButton
            variant="secondary"
            className="mb-3"
            type="button"
            onClick={cancelDeleteEvent}
          >
            Отмена
          </UDButton>
          
          <UDButton
            variant="primary"
            className="mb-3"
            type="button"
            onClick={confirmDeleteEvent}
          >
            Удалить
          </UDButton>
        </div>
      </ConfirmDeleteModal>
      <div style={{ display: 'none' }}>
        <MatchProtocolToPrint logoUrl={info?.logoUrl} matchDetails={matchDetails} ref={printRef}/>
      </div>
    </S.GameCardContainer>
  );
};

export default GamesComponentsGameCard;
