import { GameTeam } from "../../../games/domain/interfaces/Game";
import { Match } from "../interfaces/match";
import { GameDTO } from "../../../games/domain/interfaces/GameDTO";
import { SportType } from "../../../core/domain/enums/SportType";

export function matchMapper(matches: GameDTO[]): Match[] {
  const mappedMatches: Match[] = [];
  
  for (const match of matches) {
    if (!match || !match.teams || !match.teams?.length || !match.date) {
      continue
    }
    
    const teams = match.teams?.map((team) => ({
      id: team.id,
      players: [],
      score: null,
      name: team.name,
      logo: team.emblem ? {
        id: team.emblem.id,
        url: team.emblem.externalUrl,
      } : undefined,
    }));
    
    const matchMapped: Match = {
      id: match.id,
      date: new Date(match.date),
      teams: [teams[ 0 ], teams[ 1 ]] as [GameTeam | null, GameTeam | null],
      hasResults: false,
      sportType: match.sportType as SportType,
    };
    
    mappedMatches.push(matchMapped)
  }
  
  return mappedMatches
}
