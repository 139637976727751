import { createClient } from '@supabase/supabase-js';
import { MatchChronology } from "./tables/match-chronology";
import OrganizerLogoBacking from "./tables/organizer-logo-backing";
import Overlay from "./tables/overlay";

export class Supabase {
  supabase
  matchChronology
  organizerLogoBacking
  overlay
  
  constructor() {
    if (!process.env.REACT_APP_SUPABASE_URL) {
      throw new Error('REACT_APP_SUPABASE_URL is not set')
    }
    
    if (!process.env.REACT_APP_SUPABASE_KEY) {
      throw new Error('REACT_APP_SUPABASE_KEY is not set')
    }
    
    this.supabase = createClient(
      process.env.REACT_APP_SUPABASE_URL,
      process.env.REACT_APP_SUPABASE_KEY,
    )
    
    this.matchChronology = new MatchChronology(this.supabase)
    this.organizerLogoBacking = new OrganizerLogoBacking(this.supabase)
    this.overlay = new Overlay(this.supabase)
  }
}

const supabase = new Supabase()
export default supabase
