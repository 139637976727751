import { fabric } from "fabric";
import { Canvas, Group, Object, StaticCanvas } from "fabric/fabric-impl";
import customization from "./customization";
import _ from "lodash";
import { Resolution } from "../types";

class BackgroundElems {
  private canvas!: StaticCanvas | Canvas
  public border?: Object;
  private grid?: Group;
  public isGridShow = false
  public isBorderShow = false
  
  init(canvas: StaticCanvas | Canvas) {
    this.canvas = canvas
  }
  
  drawBorder(canvas: Canvas | StaticCanvas, resolution?: Resolution, style?: {
    color: string,
    width: number,
    dashArray: boolean
  }) {
    if (!canvas) return
    if (this.border) {
      const eqResolution = resolution && _.isEqual(this.border.data.resolution, resolution);
      const eqStyles = style && _.isEqual(this.border.data.style, style);
      if (!eqStyles || !eqResolution) {
        canvas.remove(this.border)
      } else {
        if (canvas.contains(this.border)) return;
        canvas?.add(this.border)
        this.border.sendToBack()
        return
      }
    }
    
    const { width, height } = resolution ?? { width: 1920, height: 1080 }
    const { color, width: strokeWidth, dashArray } = style ?? { color: 'gray', width: 1, dashArray: false }
    this.border = new fabric.Rect({
      width,
      height,
      fill: 'transparent',
      stroke: color ?? 'gray',
      strokeWidth: strokeWidth ?? 1,
      selectable: false,
      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      lockUniScaling: true,
      objectCaching: false,
      strokeDashArray: dashArray ? [10, 10] : undefined,
      data: { name: 'border', isSave: false, resolution, style },
      excludeFromExport: true,
      ...customization.getAllProperties()
    });
    canvas.add(this.border)
    this.border.sendToBack()
    this.isBorderShow = true
  }
  
  drawGrid(canvas: Canvas | StaticCanvas, data?: { resolution?: Resolution, gridStep?: number }) {
    const { resolution, gridStep = 10 } = data ?? {}
    
    if (this.grid) {
      const eqGrid = gridStep && this.grid.data.gridStep === gridStep;
      const eqResolution = resolution && _.isEqual(this.grid.data.resolution, resolution);
      
      if (!eqGrid || !eqResolution) {
        canvas.remove(this.grid)
      } else {
        if (canvas.contains(this.grid)) return
        canvas?.add(this.grid)
        this.grid.sendToBack()
        return
      }
    }
    
    if (gridStep < 5) return
    
    const { width, height } = resolution ?? { width: 1920, height: 1080 }
    this.grid = new fabric.Group([], {
      width,
      height,
      selectable: false,
      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      lockScalingX: true,
      lockScalingY: true,
      lockUniScaling: true,
      objectCaching: false,
      excludeFromExport: true, ...customization.getAllProperties()
    })
    
    this.grid.data = { name: 'grid', isSave: false, gridStep, resolution }
    
    const max = Math.max(width, height)
    for (let i = 0; i < max; i += gridStep) {
      if (i <= width) {
        this.grid.addWithUpdate(new fabric.Line([i, 0, i, height], {
          stroke: 'gray',
          strokeWidth: 0.5,
          selectable: false,
          lockMovementX: true,
          lockMovementY: true,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          lockUniScaling: true,
          objectCaching: false,
          originX: 'center',
          originY: 'center',
          ...customization.getAllProperties()
        }))
      }
      
      if (i <= height) {
        this.grid.addWithUpdate(new fabric.Line([0, i, width, i], {
          stroke: 'gray',
          strokeWidth: 0.5,
          selectable: false,
          lockMovementX: true,
          lockMovementY: true,
          lockRotation: true,
          lockScalingX: true,
          lockScalingY: true,
          lockUniScaling: true,
          objectCaching: false,
          originX: 'center',
          originY: 'center',
          ...customization.getAllProperties()
        }))
      }
    }
    
    canvas.add(this.grid)
    this.grid.sendToBack()
    this.isGridShow = true
  }
  
  hideBorder(canvas: Canvas | StaticCanvas) {
    if (!canvas) return
    
    if (this.border) {
      canvas.remove(this.border)
      this.isBorderShow = false
    }
  }
  
  hideGrid(canvas: Canvas | StaticCanvas) {
    if (!canvas) return
    
    if (this.grid) {
      canvas.remove(this.grid)
      this.isGridShow = false
    }
  }
}

const backgroundElems = new BackgroundElems()
export default backgroundElems
