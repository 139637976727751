import React, { useEffect } from "react";
import useFabric from "../../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import useReplacer from "../../../../ud-ui/components/fabric-canvas/hooks/useReplacer";
import useTemplates from "../../../../template-editor/ui/hooks/useTemplates";
import { time } from "../../../domain/constants/time";
import { fabric } from "fabric";
import { millisToMinutesAndSeconds } from "../../../domain/utils/convertTime";
import { useSelector } from "react-redux";
import useBoards from "../../../../template-editor/ui/hooks/useBoards";
import useTimer from "../../hooks/useTimer";
import { CanvasWrapper } from "./styles";
import { selectActiveBoard } from "../../../../template-editor/store/templates/selectors";
import useGameInfo from "../../hooks/useGameInfo";

export function Canvas() {
  const { canvas, manager } = useFabric('static')
  const replacer = useReplacer(manager)
  const { activeTemplate } = useTemplates()
  const { boards, activeScheme } = useBoards()
  const activeBoard = useSelector(selectActiveBoard)
  const { timer } = useTimer()
  const {
    organizer,
    goals,
    fouls,
    championship,
    teams,
    match,
    formatStartDate,
  } = useGameInfo()
  
  useEffect(() => {
    if (!manager) return
    const curActiveOverlay = activeBoard
    
    const allObject = manager.canvas.getObjects()
    const promiseAll: Promise<void>[] = []
    
    // TODO Вынести в addons
    if (allObject.length > 0) {
      for (const obj of allObject) {
        promiseAll.push(new Promise<void>((resolve) => {
          obj.animate(
            'opacity',
            0,
            {
              duration: time.second / 2,
              onChange: manager.canvas.renderAll.bind(manager.canvas),
              onComplete: resolve,
            })
        }))
      }
    }
    
    const smoothAppearance = (object: fabric.Object) => {
      object.animate('opacity', 1,
        {
          from: 0,
          duration: time.second / 2,
          onChange: manager.canvas.renderAll.bind(manager.canvas),
        })
    }
    
    Promise.all(promiseAll).then(() => {
      if (curActiveOverlay?.id !== activeBoard?.id) return
      if (!activeBoard) {
        manager.clear()
        return
      }
      
      if (!boards.length) return
      const scheme = activeScheme
      if (!scheme) return;
      
      const onLoad = () => {
        const loadedObject = manager.canvas.getObjects()
        for (const obj of loadedObject) {
          obj.set({ opacity: 0 })
        }
        
        replacer.replaceImageByIndicator('teamLogo0', teams?.first?.logo ?? '', 0, smoothAppearance)
        replacer.replaceImageByIndicator('teamLogo1', teams?.second?.logo ?? '', 0, smoothAppearance)
        replacer.replaceImageByIndicator('organizerLogo', organizer?.logo ?? '', 0, smoothAppearance)
        replacer.replaceTextByIndicator('teamName0', (teams?.first?.name ?? 'Неизвестно').toUpperCase(), 0)
        replacer.replaceTextByIndicator('teamName1', (teams?.second?.name ?? 'Неизвестно').toUpperCase(), 0)
        replacer.replaceTextByIndicator('teamScore0', goals.first.toString(), 0)
        replacer.replaceTextByIndicator('teamScore1', goals.second.toString(), 0)
        replacer.replaceTextByIndicator('teamFouls0', fouls.first.toString(), 0)
        replacer.replaceTextByIndicator('teamFouls1', fouls.second.toString(), 0)
        replacer.replaceTextByIndicator('city', match?.address.city ?? 'Неизвестный город', 0)
        replacer.replaceTextByIndicator('championshipName', championship?.name ?? 'Неизвестный чемпионат', 0)
        replacer.replaceTextByIndicator('startMatchTime', formatStartDate.time ?? 'Неизвестное время', 0)
        replacer.replaceTextByIndicator('startMatchDate', formatStartDate.date ?? 'Неизвестная дата', 0)
        replacer.replaceTextByIndicator('matchTime', millisToMinutesAndSeconds(timer), 0)
        replacer.replaceLastResultGame('lastGameResults0', teams?.first?.lastGamesResults.map((x) => x.result), 0)
        replacer.replaceLastResultGame('lastGameResults1', teams?.second?.lastGamesResults.map((x) => x.result), 0)
        replacer.replaceTeamComposition(teams?.first?.members?.map(player => ({
          name: `${player.name} ${player.surname}`,
          avatar: player.photo ?? '',
          number: player.number,
        })) ?? [], 0, { opacity: 0, onLoaded: smoothAppearance })
        replacer.replaceTeamComposition(teams?.second?.members?.map(player => ({
          name: `${player.name} ${player.surname}`,
          avatar: player.photo ?? '',
          number: player.number,
        })) ?? [], 1, { opacity: 0, onLoaded: smoothAppearance })
        
        
        const allObject = manager.canvas.getObjects()
        for (const obj of allObject) {
          smoothAppearance(obj)
        }
      }
      
      manager.loadLocal({ scheme }).then(onLoad)
    })
    
    // eslint-disable-next-line
  }, [activeBoard, manager, replacer])
  
  useEffect(() => {
    if (!manager) return
    if (!activeTemplate) return
    manager?.setResolution(activeTemplate.resolution)
    manager?.setDimensions(activeTemplate.resolution)
    manager.normalizedViewport()
  }, [activeTemplate, manager])
  
  useEffect(() => {
    replacer.updateText('teamScore0', goals.first.toString())
    replacer.updateText('teamScore1', goals.second.toString())
    replacer.updateText('teamFouls0', fouls.first.toString())
    replacer.updateText('teamFouls1', fouls.second.toString())
  }, [fouls, goals, replacer])
  
  useEffect(() => {
    replacer.updateText('matchTime', millisToMinutesAndSeconds(timer))
  }, [replacer, timer])
  
  return (
    <CanvasWrapper>
      {canvas}
    </CanvasWrapper>
  );
}
