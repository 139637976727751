/** @jsxImportSource @emotion/react */
import { Pages } from "../../../navigation/domain/enums/pages";
import React, { useEffect, useMemo } from "react";
import UDBreadcrumbs from "../../../ud-ui/components/breadcrumbs";
import UDMainLayout from "../../../ud-ui/layout/main";
import Header from "../components/catalog/header";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { fetchTemplates } from "../../store/catalog/actions";
import { selectTemplatesByFilter } from "../../store/catalog/selectors";
import TemplateItem from "../components/catalog/templateItem";
import { TemplateListCatalog } from "./styles";
import UDText from "../../../ud-ui/components/text";
import NotFound from '../../../ud-ui/components/images/notFound.png';

function Catalog() {
  const breadcrumb = useMemo(() => (<UDBreadcrumbs className="my-10" breadcrumbs={[
    { label: 'Турниры', to: Pages.TOURNEYS.INDEX },
    { label: 'Шаблоны', to: Pages.TEMPLATE.INDEX },
    { label: 'Готовые шаблоны', to: Pages.TEMPLATE.CATALOG.INDEX },
  ]}/>), []);
  
  const dispatch = useDispatch<AppDispatch>()
  const templates = useSelector(selectTemplatesByFilter);
  
  useEffect(() => {
    if (templates.length > 0) return
    dispatch(fetchTemplates())
  }, [dispatch, templates.length]);
  
  return (
    <UDMainLayout headerContent={breadcrumb}>
      <div className={'d-flex flex-column'} style={{ height: '100%', overflow: 'hidden' }}>
        <Header/>
        {templates.length > 0 ? (
          <TemplateListCatalog>
            {templates.map((template) => (
              <TemplateItem key={template.id} template={template}/>
            ))}
          </TemplateListCatalog>
        ) : (
          <div className={'flex-column h-100 w-100 flex-center'}>
            <img src={NotFound} alt={'empty'} style={{ height: 130, width: 130 }}/>
            <UDText type={'callout'} style={{ color: '#8F8E99' }}>По такому запросу ничего не найдено</UDText>
          </div>
        )}
      </div>
    </UDMainLayout>
  );
}

export default Catalog;
