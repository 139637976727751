import { Canvas, IGroupOptions, IRectOptions, Object, StaticCanvas } from "fabric/fabric-impl";
import SnapToGrid from "./snapToGrid";
import { fabric } from "fabric";
import { MyTextOptions } from "../types";
import customization from "./customization";

class Elements {
  
  getLeftTop(canvas: Canvas | StaticCanvas) {
    const viewport = canvas.viewportTransform;
    const zoom = canvas.getZoom()
    const left = SnapToGrid.snapGrid(viewport ? -1 * viewport[ 4 ] / zoom + 20 : 20);
    const top = SnapToGrid.snapGrid(viewport ? -1 * viewport[ 5 ] / zoom + 20 : 20);
    return { left, top }
  }
  
  createText(text: string, options: MyTextOptions) {
    const centerParent = options.onCenterParent?.getCenterPoint()
    
    return new fabric.Text(text, {
      left: centerParent ? centerParent.x : options.left,
      top: centerParent ? centerParent.y : options.top,
      ...customization.getAllProperties(),
      ...options,
    })
  }
  
  blockWithText(text: string, options?: {
    blockOptions?: IRectOptions,
    textOptions?: MyTextOptions,
    groupOptions?: IGroupOptions
  }) {
    const { blockOptions = {}, textOptions = {}, groupOptions = {} } = options ?? {}
    const fontSize = +(textOptions.fontSize ?? 20).toFixed()
    const block = new fabric.Rect({ ...blockOptions, strokeWidth: 0 })
    const textBlock = this.createText(text, {
      ...textOptions,
      fontSize,
      textAlign: 'center',
      onCenterParent: block,
      originX: 'center',
      originY: 'center',
      fontFamily: 'SF Pro Text, sans-serif',
      strokeWidth: 0,
      ...customization.getAllProperties(),
    })
    return new fabric.Group([block, textBlock], { ...groupOptions, strokeWidth: 0 })
  }
  
  createPlaceholder(canvas: Canvas | StaticCanvas, text: string, data: {
    name: string,
    type: string,
    hasTeam: boolean
  }) {
    const { left, top } = this.getLeftTop(canvas)
    
    return this.blockWithText(text, {
      blockOptions: {
        width: 300,
        height: 100,
        strokeWidth: 0,
        fill: 'rgba(230, 230, 230, 0.6)',
        ...customization.getAllProperties(),
      },
      textOptions: {
        fontSize: 20,
        fontFamily: 'Open Sans, sans-serif',
        fill: 'black',
        fontWeight: 'normal',
        textAlign: 'center',
        ...customization.getAllProperties(),
      },
      groupOptions: {
        left,
        top,
        ...customization.getAllProperties(),
        data: { fontSize: 20, fontFamily: 'Open Sans, sans-serif', color: '#000000', fontWeight: 'normal', ...data },
      },
    })
  }
  
  createTextPlaceholder(
    canvas: Canvas | StaticCanvas,
    text: string,
    data: { name: string, type: string, hasTeam: boolean }) {
    const { left, top } = this.getLeftTop(canvas)
    
    const textbox = new fabric.Textbox(text, {
      fontSize: 20,
      fontFamily: 'Open Sans, sans-serif',
      fill: 'black',
      fontWeight: 'normal',
      textAlign: 'center',
      originX: 'center',
      originY: 'center',
      strokeWidth: 0,
      ...customization.getAllProperties(),
    });
    
    return new fabric.Group([textbox], {
      backgroundColor: 'rgba(230, 230, 230, 0.6)',
      strokeWidth: 0,
      left,
      top,
      ...customization.getAllProperties(),
      data: {
        fontSize: 20,
        fontFamily: 'Open Sans, sans-serif',
        color: '#000000',
        fontWeight: 'normal',
        textAlign: 'center',
        ...data,
      },
    })
  }
  
  createTeamCompositionPlaceholder(canvas: Canvas | StaticCanvas) {
    const { left, top } = this.getLeftTop(canvas)
    const avatarWidth = 36
    const numberWidth = 48
    const nameWidth = 240
    const playerBlockGap = 5
    const playerBlockHeight = 36
    const playerBlockWidth = avatarWidth + numberWidth + nameWidth + playerBlockGap * 2
    
    const numColumn = 2
    const numRow = 8
    const gapRow = 8
    const gapColumn = 40
    
    
    const background = new fabric.Rect({
      width: playerBlockWidth * numColumn + gapColumn * (numColumn - 1),
      height: playerBlockHeight * numRow + gapRow * (numRow - 1),
      strokeWidth: 0,
      fill: 'rgba(230, 230, 230, 0.4)',
      ...customization.getAllProperties(),
    })
    
    // const numColumn = round((background.getScaledWidth() - gapColumn) / (334 + gapColumn))
    // const numRow = round((background.getScaledHeight() - gapRow) / (36 + gapRow))
    const blocks: Object[] = []
    
    for (let i = 0; i < numColumn; i++) {
      const left = i * playerBlockWidth + i * gapColumn
      for (let j = 0; j < numRow; j++) {
        const top = j * playerBlockHeight + j * gapRow
        blocks.push(this.createPlayerBlock(canvas, { left, top }, i * numRow + j))
      }
    }
    
    return new fabric.Group([background, ...blocks], {
      left,
      top,
      originX: 'left',
      originY: 'top',
      data: { name: "teamComposition", type: 'composition', team: 0, hasTeam: true },
      ...customization.getAllProperties(),
    })
  }
  
  private createPlayerBlock(canvas: Canvas | StaticCanvas, pos: { left: number, top: number }, number: number) {
    const { left, top } = pos
    const avatarWidth = 36
    const numberWidth = 48
    const nameWidth = 240
    const gap = 5
    
    const avatarBlock = this.blockWithText(`Аватар\nИгрока №${number + 1}`, {
      blockOptions: {
        width: avatarWidth,
        height: 36,
        strokeWidth: 0,
        fill: 'rgba(230, 230, 230, 0.8)',
        ...customization.getAllProperties(),
      },
      textOptions: {
        fontSize: 6,
        fontFamily: 'Open Sans, sans-serif',
        fill: 'black',
        fontWeight: 'normal',
        textAlign: 'center',
        ...customization.getAllProperties(),
      },
      groupOptions: {
        width: avatarWidth,
        height: 36,
        ...customization.getAllProperties(),
        data: {
          name: `playerAvatar`,
          number,
          team: 0,
          fontSize: 6,
          fontFamily: 'Open Sans, sans-serif',
          color: 'white',
          fontWeight: 'normal',
          type: 'image',
        },
      },
    })
    
    SnapToGrid.transformAfterScaling(avatarBlock)
    
    const numberBlock = this.blockWithText(`Номер\nИгрока №${number + 1}`, {
      blockOptions: {
        width: numberWidth,
        height: 36,
        strokeWidth: 0,
        fill: 'rgba(230, 230, 230, 0.8)',
        ...customization.getAllProperties(),
      },
      textOptions: {
        fontSize: 9,
        fontFamily: 'Big Shoulders Display, sans-serif',
        fill: 'black',
        fontWeight: '500',
        textAlign: 'center',
        ...customization.getAllProperties(),
      },
      groupOptions: {
        width: numberWidth,
        height: 36,
        left: avatarWidth + gap, //36 + 2,
        ...customization.getAllProperties(),
        data: {
          name: `playerNumber`,
          number,
          team: 0,
          fontSize: 9,
          fontFamily: 'Big Shoulders Display, sans-serif',
          color: 'white',
          fontWeight: '500',
          type: 'image',
        },
      },
    })
    
    SnapToGrid.transformAfterScaling(numberBlock)
    
    const nameBlock = this.blockWithText(`ФИО Игрока №${number + 1}`, {
      blockOptions: {
        width: nameWidth,
        height: 36,
        strokeWidth: 0,
        fill: 'rgba(230, 230, 230, 0.8)',
        ...customization.getAllProperties(),
      },
      textOptions: {
        fontSize: 20,
        fontFamily: 'Big Shoulders Display, sans-serif',
        fill: 'black',
        fontWeight: '500',
        textAlign: 'center',
        ...customization.getAllProperties(),
      },
      groupOptions: {
        width: nameWidth,
        height: 36,
        left: avatarWidth + numberWidth + gap + gap, // 36 + 2 + 48 + 2,
        ...customization.getAllProperties(),
        data: {
          name: `playerName`,
          number,
          team: 0,
          fontSize: 20,
          fontFamily: 'Big Shoulders Display, sans-serif',
          color: 'white',
          fontWeight: '500',
          type: 'image',
          textAlign: 'left',
        },
      },
    })
    
    SnapToGrid.transformAfterScaling(nameBlock)
    
    return new fabric.Group([avatarBlock, numberBlock, nameBlock], {
      left,
      top,
      originX: 'left',
      originY: 'top',
      data: { name: `playerBlock`, number, team: 0 },
      ...customization.getAllProperties(),
    })
  }
}

const elements = new Elements()
export default elements
