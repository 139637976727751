import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import useUniversalModal, { IUNModalProps } from "../useUniversalModal";
import boardNameValidator from "../../../../domain/validators/boardNameValidator";
import useBoards from "../../useBoards";
import { AppDispatch } from "../../../../../../store/store";
import { updateBoard } from "../../../../store/templates/actions";
import { resetPressedState } from "../../../../store/templates/templates";

function useRenameModal() {
  const dispatch = useDispatch<AppDispatch>();
  const { activeBoard, boards, isProcessed, isFinished } = useBoards()
  
  const validator = useCallback((values: { [ p: string ]: string }) => {
    const errors: { [ p: string ]: string | undefined } = {}
    
    const nameError = boardNameValidator(values.name?.trim(), boards.map(board => board.name))
    if (nameError) {errors.name = nameError}
    
    return errors
  }, [boards]);
  
  const onSubmit = useCallback((values: { [ p: string ]: string }) => {
    if (!activeBoard) return
    dispatch(updateBoard({ id: activeBoard.id, name: values.name.trim() }))
  }, [activeBoard, dispatch]);
  
  const data: IUNModalProps = useMemo(() => {
    return {
      initialValue: {
        name: activeBoard?.name ?? '',
      },
      headerText: 'Переименовать',
      validator,
      buttonText: 'Сохранить',
      input: {
        placeholder: 'Название табло',
      },
      onSubmit,
      isProcessed,
    }
  }, [activeBoard?.name, isProcessed, onSubmit, validator]);
  
  const { modal, close, open } = useUniversalModal(data)
  
  useEffect(() => {
    if (!isFinished) { return; }
    close()
    dispatch(resetPressedState())
  }, [close, dispatch, isFinished]);
  
  return { modal, close, open }
}

export default useRenameModal;
