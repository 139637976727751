import styled from "@emotion/styled";

export const BoardsList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  min-width: 260px;
  width: 260px;

  max-height: 400px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fcfcfc;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2a2929;
    border-radius: 8px;
  }
`

export const Header = styled.div<{ backgroundColor?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px 8px 0 0;
  
  background-color: #EAEAEC;
  font-size: 17px;
  color: #1A1A29;
  padding: 8px 16px;
`

export const List = styled.div`
  background-color: white;
  padding-block: 8px;
  border-radius: 0 0 8px 8px;
`

export const BoardItem = styled.div`
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: transparent;
  height: 38px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  p {
    width: 100%;
    max-width: 100%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
