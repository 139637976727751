import { ChampionshipDTO } from "../interfaces/dtos";
import { matchMapper } from "./match-mapper";
import { mapChampionshipSettings } from "../../../tourneys/domain/data-mappers/championship-game-teams-mapper";
import { Calendar } from "../interfaces/calendar";
import { Championship } from "../interfaces/championship";

export function extendedChampionshipsMapper(championships: ChampionshipDTO[]): Calendar {
  const calendar: Calendar = {}
  
  for (const championship of championships) {
    if (!championship.matches || !championship.matches?.length) {
      continue
    }
    
    const mappedChampionship: Championship = {
      id: championship.id,
      name: championship.name,
      tournamentId: championship.tournament.id,
      address: championship.tournament.address,
      settings: mapChampionshipSettings(championship.settings),
    }
    
    const matches = matchMapper(championship.matches)
    for (const match of matches) {
      const { date } = match
      
      const month = date.toLocaleString('ru-RU', { month: 'long' });
      calendar[ month ] = calendar[ month ] || [];
      let day = calendar[ month ].find(d => d.date.getDate() === date.getDate());
      if (!day) {
        day = { date, matches: [] };
        calendar[ month ].push(day);
      }
      
      day.matches.push({
        ...match,
        address: championship.tournament.address.city ?? 'Неизвестный город',
        championship: mappedChampionship,
      });
    }
  }
  
  return calendar
}
