/** @jsxImportSource @emotion/react */
import { buttonCss, MainBlock } from "./styles";
import Stars from "../../../../ud-ui/components/icon/stars";
import UDText from "../../../../ud-ui/components/text";
import UDButton from "../../../../ud-ui/components/button";
import useCopyModal from "../../hooks/modals/catalog/copy";

interface IProps {
  templateId: number
}

function Header(props: IProps) {
  const { templateId } = props
  
  const { modal: copyModal, open: onOpenCopy } = useCopyModal(templateId)
  return (
    <MainBlock>
      <div className={'flex-center'}>
        <Stars style={{ minWidth: 52 }}/>
        <UDText className={'ml-2'} type={'subhead'}>
          Для редактирования этого шаблона необходимо сделать копию
        </UDText>
      </div>
      <UDButton css={buttonCss} variant={'light'} onClick={onOpenCopy}>
        Скопировать
      </UDButton>
      {copyModal}
    </MainBlock>
  );
}

export default Header;
