import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import useUniversalModal, { IUNModalProps } from "../useUniversalModal";
import boardNameValidator from "../../../../domain/validators/boardNameValidator";
import useBoards from "../../useBoards";
import { useOrganizer } from "../../../../../organizer/ui/hooks/useOrganizer";
import { createBoard } from "../../../../store/templates/actions";
import useTemplates from "../../useTemplates";
import { AppDispatch } from "../../../../../../store/store";
import { resetPressedState } from "../../../../store/templates/templates";

function useCreateModal() {
  const dispatch = useDispatch<AppDispatch>();
  const { info } = useOrganizer()
  const { boards, isProcessed, isFinished } = useBoards()
  const { activeTemplate } = useTemplates()
  
  const validator = useCallback((values: { [ p: string ]: string }) => {
    const errors: { [ p: string ]: string | undefined } = {}
    
    const nameError = boardNameValidator(values.name?.trim(), boards.map(boards => boards.name))
    if (nameError) {errors.name = nameError}
    
    return errors
  }, [boards]);
  
  const onSubmit = useCallback((values: { [ p: string ]: string }) => {
    if (!info) {
      console.warn({ event: 'failed to create category: info is undefined', info });
      return
    }
    
    if (!activeTemplate) {
      console.warn({ event: 'failed to create category: activeTemplate is undefined', activeTemplate });
      return
    }
    
    const { name } = values
    dispatch(createBoard({ templateId: activeTemplate.id, name: name.trim(), version: '5.3.0', objects: [] }))
  }, [activeTemplate, dispatch, info]);
  
  const data: IUNModalProps = useMemo(() => {
    return {
      headerText: 'Новое табло',
      initialValue: {
        name: '',
      },
      validator,
      buttonText: 'Создать',
      input: {
        placeholder: 'Название табло',
      },
      onSubmit,
      isProcessed,
    }
  }, [isProcessed, onSubmit, validator]);
  
  const { modal, close, open } = useUniversalModal(data)
  
  useEffect(() => {
    if (!isFinished) { return; }
    close()
    dispatch(resetPressedState())
  }, [close, dispatch, isFinished]);
  
  return { modal, close, open }
}

export default useCreateModal;
