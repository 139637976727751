import { TemplateExtend } from "../interfaces/supabase/overlay/auxiliary-types";
import boardDataMapper from "./board-data-mapper";
import { TemplateExtendDTO } from "../interfaces/supabase/overlay/dto";

const templateExtendDataMapper = (template: TemplateExtendDTO): TemplateExtend => {
  return {
    ...template,
    createdAt: new Date(template.created_at),
    ownerId: template.owner_id,
    boards: template.boards.map(boardDataMapper),
    sportType: template.sport_type,
  }
};

export default templateExtendDataMapper;
