import React, { useCallback, useMemo, useState } from 'react';
import { Button, EmptyButton, Item, LongDivider, ShortDivider, SubItem } from "./styles";
import UDIcon from "../../../../../ud-ui/components/icon";
import alignment from "../../../../../ud-ui/components/fabric-canvas/addons/alignment/alignment";
import { Group, Object, Textbox } from "fabric/fabric-impl";
import history from "../../../../../ud-ui/components/fabric-canvas/addons/history";
import { selectFabricManager } from "../../../../store/editor/selectors";
import { useSelector } from "react-redux";
import ChoosingTeam from "./components/choosingTeam";
import layerManager from "../../../../../ud-ui/components/fabric-canvas/addons/layerManager";
import ArrowRightIcon from "../../../../../ud-ui/components/icon/arrow-right-icon";

interface IProps {
  objects: Object[]
}

const isHasTeam = (object: Object) => {
  if (object.type !== 'group') return false
  if (!object.data) return false
  // TODO Добавить во все стандартные шаблоны в data поле hasTeam
  const lastChar = object.data.name?.slice(-1)
  const lastCharIsNum = /^\d+$/.test(lastChar)
  return object.data.hasTeam || lastCharIsNum
}

const getPlaceholdersWithTeam = (objects: Object[]): Group[] => {
  return objects.filter(isHasTeam) as Group[]
}

function ManyObjects(props: IProps) {
  const { objects } = props;
  const placeholdersWithTeam = useMemo(() => getPlaceholdersWithTeam(objects), [objects]);
  const fabricManager = useSelector(selectFabricManager);
  const [teamIndex, setTeamIndex] = useState((+placeholdersWithTeam[ 0 ]?.data.name.at(-1)) as 0 | 1);
  
  const onChangeTeam = useCallback((teamIndex: 0 | 1) => {
    placeholdersWithTeam.forEach((placeholder) => {
      switch (placeholder.data?.type) {
        case 'composition': {
          placeholder.data = { ...placeholder.data, team: teamIndex }
          break;
        }
        default: {
          const name = placeholder.data.name
          placeholder.data = { ...placeholder.data, name: name.slice(0, -1) + teamIndex }
          const type = placeholder.data.type
          let text: Textbox | undefined
          switch (type) {
            case 'text': {
              text = placeholder.getObjects()[ 0 ] as Textbox | undefined
              break
            }
            case 'image': {
              text = placeholder.getObjects()[ 1 ] as Textbox | undefined
              break;
            }
          }
          
          if (!text || !text.text) return
          const textName = text.text.slice(0, -1) + (teamIndex + 1)
          text?.set({ text: textName })
        }
      }
    })
    
    setTeamIndex(teamIndex)
    fabricManager?.render()
    history.saveState()
  }, [fabricManager, placeholdersWithTeam]);
  
  return (
    <>
      <Item>
        Фигуры
      </Item>
      <ShortDivider/>
      <Item>
        <SubItem style={{ width: '100%', justifyContent: 'space-between' }}>
          <EmptyButton onClick={alignment.group.left}><UDIcon name={'align-left'}/></EmptyButton>
          <EmptyButton onClick={alignment.group.centerVertical}><UDIcon name={'align-center-vertical'}/></EmptyButton>
          <EmptyButton onClick={alignment.group.right}><UDIcon name={'align-right'}/></EmptyButton>
          <EmptyButton onClick={alignment.group.top}><UDIcon name={'align-top'}/></EmptyButton>
          <EmptyButton onClick={alignment.group.centerHorizontal}><UDIcon
            name={'align-center-horizontal'}/></EmptyButton>
          <EmptyButton onClick={alignment.group.bottom}><UDIcon name={'align-bottom'}/></EmptyButton>
        </SubItem>
      </Item>
      <LongDivider/>
      {placeholdersWithTeam.length > 0 && (
        <Item>
          <ChoosingTeam onChange={onChangeTeam} value={teamIndex}/>
        </Item>
      )}
      <Item>
        Слои
        <Button onClick={() => layerManager.sendToFront(fabricManager?.canvas.getActiveObject() as Object)}>
          <ArrowRightIcon height={16} width={16} color={'#929292'} direction={'up'}/> Поднять вверх
        </Button>
        <Button onClick={() => layerManager.sendToBack(fabricManager?.canvas.getActiveObject() as Object)}>
          <ArrowRightIcon height={16} width={16} color={'#929292'} direction={'down'}/> Переместить вниз
        </Button>
      </Item>
    </>
  );
}

export default ManyObjects;
