/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import styled from "@emotion/styled";
import { EmptyButton } from "../components/editor/rightToolbar/styles";
import ArrowLeftIcon from "../../../ud-ui/components/icon/arrow-left-icon";
import { css } from "@emotion/react";
import useFabric from "../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import useReplacer from "../../../ud-ui/components/fabric-canvas/hooks/useReplacer";
import { format } from "date-fns";
import ru from "date-fns/locale/ru";
import { useSelector } from "react-redux";
import image from '../../../ud-ui/components/images/placeholders/background.png'
import { history } from "../../../../store/store";
import { Pages } from "../../../navigation/domain/enums/pages";
import tournamentLogo from "../../../ud-ui/components/images/placeholders/tournamentLogo.png"
import logo1 from "../../../ud-ui/components/images/placeholders/logo1.png"
import logo2 from "../../../ud-ui/components/images/placeholders/logo2.png"
import backgroundElems from "../../../ud-ui/components/fabric-canvas/addons/backgroundElems";
import { selectPreviewState } from "../../store/editor/selectors";
import { Player } from "../../../ud-ui/components/fabric-canvas/addons/replacer";

function TemplatePreview() {
  const state = useSelector(selectPreviewState)
  const { canvas, manager } = useFabric('static', { data: state?.resolution })
  const replacer = useReplacer(manager)
  
  if (state?.scheme === undefined) {
    history.push(Pages.TEMPLATE.EDITOR)
  }
  
  useEffect(() => {
    if (!manager) return
    if (!state?.scheme) return
    const paramsForBorder = { color: '#F5D956', width: 2, dashArray: true }
    
    manager.loadLocal({ scheme: state.scheme })
      .then(() => {
          backgroundElems.drawBorder(manager.canvas, manager.resolution.value, paramsForBorder)
          const now = Date.now()
          
          replacer.replaceImageByIndicator('teamLogo0', logo1)
          replacer.replaceImageByIndicator('teamLogo1', logo2)
          replacer.replaceImageByIndicator('organizerLogo', tournamentLogo)
          replacer.replaceTextByIndicator('teamName0', 'СЛОНЫ')
          replacer.replaceTextByIndicator('teamName1', 'ФЕНИКСЫ')
          replacer.replaceTextByIndicator('teamScore0', '2')
          replacer.replaceTextByIndicator('teamScore1', '7')
          replacer.replaceTextByIndicator('teamFouls0', '3')
          replacer.replaceTextByIndicator('teamFouls1', '4')
          replacer.replaceTextByIndicator('city', 'Новосибирск')
          replacer.replaceTextByIndicator('championshipName', 'Кубок мира')
          replacer.replaceTextByIndicator('startMatchTime', format(now, 'HH:mm'))
          replacer.replaceTextByIndicator('startMatchDate', format(now, 'd MMMM', { locale: ru }))
          replacer.replaceTextByIndicator('matchTime', '14:22')
          replacer.replaceLastResultGame('lastGameResults0', ['WON', 'DRAW', 'LOST'])
          replacer.replaceLastResultGame('lastGameResults1', ['LOST', 'DRAW', 'WON'])
          replacer.replaceTeamComposition(fakePlayersTeam0, 0)
          replacer.replaceTeamComposition(fakePlayersTeam1, 1)
          
          backgroundElems.border?.bringToFront()
        },
      )
    
    manager.canvas.setWidth(window.innerWidth)
    manager.canvas.setHeight(window.innerHeight - 88)
    const offset = { zoom: 0.999 };
    manager.normalizedViewport(offset)
    
    const resizing = () => {
      manager.canvas.setWidth(window.innerWidth)
      manager.canvas.setHeight(window.innerHeight - 88)
      backgroundElems.drawBorder(manager.canvas, manager.resolution.value, paramsForBorder)
      manager.normalizedViewport(offset)
    }
    
    window.addEventListener('resize', resizing)
    
    return () => {
      window.removeEventListener('resize', resizing)
    }
  }, [manager, replacer, state?.scheme])
  
  const onBackToEditor = () => {
    history.push(Pages.TEMPLATE.EDITOR)
  }
  
  return (
    <MainBlock>
      <Header>
        <EmptyButton onClick={onBackToEditor} css={buttonStyle}>
          <ArrowLeftIcon/>
          Назад
        </EmptyButton>
      </Header>
      <CanvasWrapper>
        {canvas}
      </CanvasWrapper>
    </MainBlock>
  );
}

export default TemplatePreview;

const fakePlayersTeam0: Player[] = [
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Константин Константинопольский",
    number: 15,
  },
  {
    avatar: '',
    name: "Михаил Михайлов",
    number: 21,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Егор Егоров",
    number: 34,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Иван Иванов",
    number: 45,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Александр Виноградов Александрович",
    number: 77,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Александр Александров",
    number: 88,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Александр Александров",
    number: 99,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Александр Александров",
    number: 100,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Александр Александров",
    number: 101,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Александр Александров",
    number: 102,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Александр Александров",
    number: 103,
  },
  
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Александр Александров",
    number: 104,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Александр Александров",
    number: 105,
  },
  
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Александр Александров",
    number: 106,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Александр Александров",
    number: 107,
  },
  
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Александр Александров",
    number: 108,
  },
]

const fakePlayersTeam1: Player[] = [
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Криштиану Роналду",
    number: 5,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Лионель Месси",
    number: 31,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Роберт Левандовский",
    number: 81,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Кевин де Брюйне",
    number: 91,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Джанлуиджи Буффон",
    number: 99,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Криштиану Роналду",
    number: 5,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Лионель Месси",
    number: 31,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Роберт Левандовский",
    number: 81,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Кевин де Брюйне",
    number: 91,
  },
  {
    avatar: 'https://www.iconexperience.com/_img/o_collection_png/green_dark_grey/512x512/plain/user.png',
    name: "Джанлуиджи Буффон",
    number: 99,
  },
]

const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`

const Header = styled.div`
  display: flex;
  padding: 30px;
  background-color: #1A1A29;
  height: 88px;
`

const buttonStyle = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  gap: 10px;
  background-color: #FFFFFF;
  padding: 4px 10px;
  border-radius: 6px;
  
  font-family: Inter, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #000000;
`

const CanvasWrapper = styled.div`
  width: 100vw;
  height: 100%;
  
  background: url("${image}") no-repeat center center fixed;
  background-size: cover;
  overflow: hidden;
`
