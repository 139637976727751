import { Event } from "../../../domain/interfaces/events";
import _ from "lodash";
import { Member } from "../../../domain/interfaces/gameInfo/gameInfoMapped";
import { GameInfoSliceState } from "../gameInfo";

function parseInfoEvent(state: GameInfoSliceState, event: Event, playerIndex = 0) {
  const isFirst = event.teamId === state.teams?.first.id;
  const teamPrefix = isFirst ? 'first' : 'second' as 'first' | 'second';
  const team = state.teams?.[ teamPrefix ]
  const playerId = event.players[ playerIndex ].id
  const player = _.find(team?.members, { id: playerId }) as Member | undefined;
  return { player, team, teamPrefix }
}

export default parseInfoEvent
