import { createSlice } from '@reduxjs/toolkit';
import { Event } from '../../domain/interfaces/events';

export type OverlaySliceState = {
  isLoading: boolean,
  isLoaded: boolean,
  
  featureIsAvailable?: boolean
  events: Event[]
};

const initialState: OverlaySliceState = {
  isLoading: false,
  isLoaded: false,
  
  events: [],
};

export const overlaySlice = createSlice({
  name: 'overlay',
  initialState,
  reducers: {
    setEvents(state, action: { payload: Event[] }) {
      state.events = action.payload;
      
      const lastEvent = action.payload[ action.payload.length - 1 ]
      if (!lastEvent) return
      if (!lastEvent.players?.length) return
      if (!lastEvent.players[ 0 ]) return
      
      // if (!state.gameInfo) return
      // if (!state.gameInfo.teams) return
      // if (!state.gameInfo.teams.firstTeam) return
      // if (!state.gameInfo.teams.secondTeam) return
      // const allMembers = state.gameInfo.teams.firstTeam.members?.concat(state.gameInfo.teams.secondTeam.members ?? []) ?? []
      // const member = allMembers.find((member) => member.id === lastEvent.players[ 0 ].id)
      // const teamIndex = state.gameInfo.teams.firstTeam.id === lastEvent.teamId ? 0 : 1
      // if (!member) return
      //
      // switch (lastEvent.type) {
      //   case 'GOAL':
      //   case 'PENALTY_GOAL':
      //   case 'PASS_AND_GOAL':
      //     member.qtyGoalsMatch += 1
      //     const team = state.gameInfo.teams[ teamIndex === 0 ? 'firstTeam' : 'secondTeam' ];
      //     if (team && team.score) {
      //       team.score += 1;
      //     }
      //     state.score[ teamIndex ] += 1
      //     break
      //   case'RED_CARD':
      //     member.qtyRedCardsMatch += 1
      //     break
      //   case 'YELLOW_CARD':
      //     member.qtyYellowCardsMatch += 1
      //     break
      //   case 'FOUL':
      //     state.fouls[ teamIndex ] += 1
      //     break
      // }
    },
    // setStatsForMember(state, action: {
    //   payload: { id: number, stats: { qtyGoalsMatch: number, qtyYellowCardsMatch: number, qtyRedCardsMatch: number } }
    // }) {
    //   if (!state.gameInfo) return
    //   const allMembers = state.gameInfo.teams?.firstTeam?.members?.concat(state.gameInfo.teams?.secondTeam?.members ?? []) ?? []
    //   const member = allMembers.find((member) => member.id === action.payload.id)
    //   if (!member) return
    //   member.qtyGoalsMatch = action.payload.stats.qtyGoalsMatch
    //   member.qtyYellowCardsMatch = action.payload.stats.qtyYellowCardsMatch
    //   member.qtyRedCardsMatch = action.payload.stats.qtyRedCardsMatch
    // },
  },
});

export const {
  setEvents,
} = overlaySlice.actions
