export default function boardNameValidator(boardName?: string, boardsList?: string[]) {
  if (!boardName) {
    return 'Название табло не может быть пустым'
  }
  // if (templateName.length > 30) {
  //   return 'Название табло слишком длинное'
  // }
  if (boardName.length <= 0) {
    return 'Название табло не может быть пустым'
  }
  if (boardsList && boardsList.includes(boardName)) {
    return 'Такое название уже существует, придумайте новое.'
  }
  return undefined
}
