import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TemplateExtend } from "../../../core/domain/interfaces/supabase/overlay/auxiliary-types";
import { createAddLikeReducer, createAddViewReducer, createFetchTemplatesReducer } from "./reducers";

export interface CatalogSliceState {
  templates: TemplateExtend[]
  filterByName: string
  sortByDate: 'none' | 'desc' | 'asc'
  
  isLoaded: boolean
  isLoading: boolean
  isProcessed: boolean
  isFinished: boolean
}

const initialState: CatalogSliceState = {
  templates: [],
  filterByName: '',
  sortByDate: 'none',
  
  isLoaded: false,
  isLoading: false,
  isProcessed: false,
  isFinished: false,
};

export const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setFilterByName: (state, action: PayloadAction<CatalogSliceState['filterByName']>) => {
      state.filterByName = action.payload
    },
    setSortByDate: (state, action: PayloadAction<CatalogSliceState['sortByDate']>) => {
      state.sortByDate = action.payload
    },
  },
  extraReducers: builder => {
    createFetchTemplatesReducer(builder);
    createAddLikeReducer(builder);
    createAddViewReducer(builder);
  },
});

export const {
  setFilterByName,
  setSortByDate,
} = catalogSlice.actions

export default catalogSlice.reducer
