import { TemplateExtend } from "../../../core/domain/interfaces/supabase/overlay/auxiliary-types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import supabase from "../../../core/supabase/supabase";

const PREFIX = 'catalog$';

export const fetchTemplates = createAsyncThunk<TemplateExtend[], undefined>(
  `${PREFIX}/fetchTemplates`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.getTemplates({ access: ["default", 'public'], visibility: "all" });
    } catch (error) {
      console.error(`${PREFIX}/fetchTemplates error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  });

export const addLike = createAsyncThunk<TemplateExtend, {
  templateId: number,
  userId: number
}>(
  `${PREFIX}/addLike`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.addLikeToTemplate(payload);
    } catch (error) {
      console.error(`${PREFIX}/addLike error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  });

export const addView = createAsyncThunk<TemplateExtend, {
  templateId: number,
  userId: number
}>(
  `${PREFIX}/addView`,
  async (payload, { rejectWithValue }) => {
    try {
      return await supabase.overlay.addViewToTemplate(payload);
    } catch (error) {
      console.error(`${PREFIX}/addView error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  });
