import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from "react-redux";
import { Group, Textbox } from "fabric/fabric-impl";
import { fabric } from "fabric";
import { round } from "../../../../domain/utils/helpers";
import { Button, EmptyButton, Item, LongDivider, ShortDivider, SubItem } from "./styles";
import Input, { IconWrapper } from "../../elements/input";
import Selector from "../../elements/selector";
import ColorPicker from "../../elements/colorPicker";
import AlignTextLeftIcon from "../../../../../ud-ui/components/icon/align-text-left-icon";
import AlignTextCenterIcon from "../../../../../ud-ui/components/icon/align-text-center-icon";
import AlignTextRightIcon from "../../../../../ud-ui/components/icon/align-text-right-icon";
import history from "../../../../../ud-ui/components/fabric-canvas/addons/history";
import SnapToGrid from "../../../../../ud-ui/components/fabric-canvas/addons/snapToGrid";
import layerManager from "../../../../../ud-ui/components/fabric-canvas/addons/layerManager";
import ArrowRightIcon from "../../../../../ud-ui/components/icon/arrow-right-icon";
import { selectFabricManager } from "../../../../store/editor/selectors";
import ChoosingTeam from "./components/choosingTeam";

interface IProps {
  group: Group;
}

function Placeholder(props: IProps) {
  const { group } = props
  const figure = group
  const fabricManager = useSelector(selectFabricManager);
  
  const { width, height, top, left } = figure.getBoundingRect(true) ?? defaultBounds;
  const type: string = figure.data?.type ?? 'img'
  const hasTeam = useMemo(() => {
    // TODO Добавить во все стандартные шаблоны в data поле hasTeam
    const lastChar = figure.data?.name?.slice(-1)
    const lastCharIsNum = /^\d+$/.test(lastChar)
    return figure.data?.hasTeam || lastCharIsNum
  }, [figure.data?.hasTeam, figure.data?.name]);
  
  const [teamIndex, setTeamIndex] = useState<0 | 1>((+figure.data?.name?.at(-1)) as 0 | 1)
  const [position, setPosition] = useState<{ x: number, y: number }>({ x: left, y: top })
  const [size, setSize] = useState<{ width: number, height: number }>({ width: round(width), height: round(height) })
  
  const [fontColor, setFontColor] = React.useState(group?.data?.color);
  const [fontSize, setFontSize] = React.useState(group?.data?.fontSize);
  const [fontWeight, setFontWeight] = React.useState(group?.data?.fontWeight);
  const [fontFamily, setFontFamily] = React.useState(group?.data?.fontFamily);
  
  useEffect(() => {
    setPosition({ x: round(left), y: round(top) })
    setSize({ width: round(width), height: round(height) })
    setFontColor(group?.data?.color);
    setFontSize(group?.data?.fontSize);
    setFontWeight(group?.data?.fontWeight);
    setFontFamily(group?.data?.fontFamily);
    setTeamIndex((+group.data?.name?.at(-1)) as 0 | 1)
    
    let updatePosition = () => {
      const x = figure?.get('left') ?? 0
      const y = figure?.get('top') ?? 0
      setPosition({ x: round(x), y: round(y) })
    };
    
    let updateSize = () => {
      const { width, height } = figure?.getBoundingRect(true) ?? defaultBounds
      setSize({ width: round(width), height: round(height) })
    };
    
    fabricManager?.canvas.on('mouse:up', updatePosition)
    fabricManager?.canvas.on('mouse:up', updateSize)
    
    return () => {
      fabricManager?.canvas.off('mouse:up', updatePosition)
      fabricManager?.canvas.off('mouse:up', updateSize)
    }
    
    // eslint-disable-next-line
  }, [group])
  
  const onChangePosition = (x: number, y: number) => {
    figure.setPositionByOrigin(new fabric.Point(x, y), 'left', 'top')
    setPosition({ x: round(x), y: round(y) })
    fabricManager?.render()
  }
  
  const onChangeSize = (width: number, height: number) => {
    figure.set({ scaleX: width / (figure?.width ?? 1), scaleY: height / (figure?.height ?? 1) })
    setSize({ width: round(width), height: round(height) })
    SnapToGrid.transformAfterScaling(figure)
    fabricManager?.render()
  }
  
  const onChangeFontColor = (color: string) => {
    setFontColor(color)
    const text = group.getObjects()[ 0 ] as Textbox
    if (!text) return
    if (group.data?.color === undefined) return
    group.data = { ...group.data, color }
    text.set("fill", color)
    fabricManager?.render()
    history.saveState()
  }
  
  const onChangeFontSize = (value: number) => {
    if (isNaN(value)) return
    setFontSize(value)
    if (group.data?.fontSize === undefined) return
    group.data = { ...group.data, fontSize: value }
    fabricManager?.render()
    history.saveState()
  }
  
  const onChangeFontFamily = (value: string) => {
    setFontFamily(value)
    const text = group.getObjects()[ 0 ] as Textbox
    if (!text) return
    if (group.data?.fontFamily === undefined) return
    group.data = { ...group.data, fontFamily: value }
    text.set("fontFamily", value)
    SnapToGrid.transformAfterScaling(group)
    fabricManager?.render()
    history.saveState()
  }
  const onChangeFontWeight = (value: string) => {
    setFontWeight(value)
    const text = group.getObjects()[ 0 ] as Textbox
    if (!text) return
    if (group.data?.fontWeight === undefined) return
    group.data = { ...group.data, fontWeight: value }
    text.set("fontWeight", value)
    SnapToGrid.transformAfterScaling(group)
    fabricManager?.render()
    history.saveState()
  }
  
  const onChangeTextAlign = (value: string) => {
    const text = group.getObjects()[ 0 ] as Textbox
    if (!text) return;
    if (group.data?.textAlign === undefined) return;
    group.data = { ...group.data, textAlign: value }
    text.set("textAlign", value)
    fabricManager?.render()
    history.saveState()
  }
  
  const onFocusField = () => {
    fabricManager?.render()
    history.saveState()
  }
  
  const onChangeTeam = useCallback((teamIndex: 0 | 1) => {
    const name = figure.data?.name
    figure.data = { ...figure.data, name: name.slice(0, -1) + teamIndex }
    const type = figure.data?.type
    let text: Textbox | undefined
    switch (type) {
      case 'text': {
        text = figure.getObjects()[ 0 ] as Textbox | undefined
        break
      }
      case 'image': {
        text = figure.getObjects()[ 1 ] as Textbox | undefined
        break;
      }
    }
    
    if (text?.text) {
      const textName = text.text.slice(0, -1) + (teamIndex + 1)
      text?.set({ text: textName })
    }
    
    fabricManager?.render()
    history.saveState()
    setTeamIndex(teamIndex)
  }, [fabricManager, figure]);
  
  const isDev = process.env.NODE_ENV === 'development'
  const showDebugInfo = true;
  
  return (
    <>
      <Item>
        <div>Фигура</div>
      </Item>
      <ShortDivider/>
      <Item>
        <SubItem>
          <span>Расположение</span>
          <Input
            onFocus={onFocusField}
            style={{ width: 73 }}
            icon={<IconWrapper>X</IconWrapper>}
            value={position.x.toString()}
            onChange={(value) => onChangePosition(+value, position.y)}
          />
          <Input
            onFocus={onFocusField}
            style={{ width: 73 }}
            icon={<IconWrapper>Y</IconWrapper>}
            value={position.y.toString()}
            onChange={(value) => onChangePosition(position.x, +value)}
          />
        </SubItem>
        <SubItem>
          <span>Размер</span>
          <Input
            onFocus={onFocusField}
            icon={<IconWrapper>Ш</IconWrapper>}
            value={size.width.toString()}
            onChange={(value) => onChangeSize(+value, size.height)}
          />
          <Input
            onFocus={onFocusField}
            icon={<IconWrapper>В</IconWrapper>}
            value={size.height.toString()}
            onChange={(value) => onChangeSize(size.width, +value)}
          />
        </SubItem>
      </Item>
      {hasTeam && (
        <>
          <LongDivider/>
          <Item>
            <ChoosingTeam onChange={onChangeTeam} value={teamIndex}/>
          </Item>
        </>
      )}
      <LongDivider/>
      {type === 'text' && (
        <>
          <Item>
            <div>Текст</div>
          </Item>
          <ShortDivider/>
          <Item>
            <span>Тип шрифта</span>
            <Selector
              onFocus={onFocusField}
              styledEachOption
              onChange={(event) => {onChangeFontFamily((event?.value) ?? '')}}
              options={fontOptions}
              value={fontOptions.find((option) => option.value === fontFamily)}
            />
            <SubItem style={{ display: 'flex', gap: 8, justifyContent: 'space-between' }}>
              <Selector
                onFocus={onFocusField}
                className={'w-100'}
                onChange={(event) => {onChangeFontWeight((event?.value) ?? '')}}
                options={fontWeightsOptions}
                value={fontWeightsOptions.find((option) => option.value === fontWeight)}
              />
              <Input
                onFocus={onFocusField}
                style={{ width: 55 }}
                unit="px"
                onChange={(value) => {onChangeFontSize(+value)}}
                value={fontSize?.toString() ?? '20'}/>
            </SubItem>
          </Item>
          <LongDivider/>
          <Item>
            <span>Цвет шрифта</span>
            <ColorPicker color={fontColor?.toUpperCase() ?? '#000000'} onChange={onChangeFontColor}/>
          </Item>
          <LongDivider/>
          <Item style={{ flexDirection: 'row', gap: 8, justifyContent: 'left' }}>
            <EmptyButton onClick={() => {onChangeTextAlign('left') }}><AlignTextLeftIcon/></EmptyButton>
            <EmptyButton onClick={() => {onChangeTextAlign('center') }}><AlignTextCenterIcon/></EmptyButton>
            <EmptyButton onClick={() => {onChangeTextAlign('right') }}><AlignTextRightIcon/></EmptyButton>
            {/*<EmptyButton onClick={() => {onChangeTextAlign('justify') }}><AlignTextJustifyIcon/></EmptyButton>*/}
          </Item>
        </>
      )}
      <Item>
        Слои
        <Button onClick={() => layerManager.sendToFront(figure)}>
          <ArrowRightIcon height={16} width={16} color={'#929292'} direction={'up'}/> Поднять вверх
        </Button>
        <Button onClick={() => layerManager.sendToBack(figure)}>
          <ArrowRightIcon height={16} width={16} color={'#929292'} direction={'down'}/> Переместить вниз
        </Button>
      </Item>
      {isDev && showDebugInfo && (
        <Item>
          data: <span style={{ whiteSpace: 'pre' }}>{JSON.stringify(group.data, null, 2)}</span>
        </Item>
      )}
    </>
  );
}

export default Placeholder;

const defaultBounds = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
}

const fonts = {
  OpenSans: 'Open Sans, sans-serif',
  BigShouldersDisplay: 'Big Shoulders Display, sans-serif',
  Inter: 'Inter, sans-serif',
  Lato: 'Lato, sans-serif',
  'Nunito Sans': 'Nunito Sans, sans-serif',
  Poppins: 'Poppins, sans-serif',
  Roboto: 'Roboto, sans-serif',
  'Source Sans Pro': 'Source Sans Pro, sans-serif',
  'Ubuntu': 'Ubuntu, sans-serif',
}

const fontOptions = Object.entries(fonts).map(font => ({ value: font[ 1 ], label: font[ 0 ] }));

const fontWeights = {
  Bolder: '900',
  Bold: 'bold',
  Normal: 'normal',
  Lighter: 'lighter',
}
const fontWeightsOptions = Object.entries(fontWeights).map(font => ({ value: font[ 1 ], label: font[ 0 ] }));
