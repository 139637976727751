import { RootState } from "../../../../store/store";
import { createSelector } from "@reduxjs/toolkit";

const selectEditor = (state: RootState) => state.templateEditor.editor;

export const selectFabricManager = createSelector(
  selectEditor,
  (state) => state.fabricManager,
)
export const selectActiveObjects = createSelector(
  selectEditor,
  (state) => state.activeObjects,
)
export const selectPreviewState = createSelector(
  selectEditor,
  (state) => state.previewState,
)
export const selectActiveModal = createSelector(
  selectEditor,
  (state) => state.activeModal,
)
export const selectSavedScheme = createSelector(
  selectEditor,
  (state) => state.savedScheme,
)
export const selectHasChange = createSelector(
  selectEditor,
  (state) => state.hasChanges,
)
export const selectIsInit = createSelector(
  selectEditor,
  (state) => state.isInit,
)
