import styled from "@emotion/styled";

export const MainBlock = styled.div`
  padding: 30px 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  
  height: calc(100%);
  overflow: hidden;
`

export const GamesWrapper = styled.div`
  overflow: auto;
  height: calc(100% - 38px);
  border-radius: 8px;
  
  &::-webkit-scrollbar {
    width: 4px;
    background-color: inherit;
    border-radius: 8px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #EAEAEA;
    border-radius: 8px;
  }
`
