import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { durations } from '../../domain/constants/time';
import { selectOverlayState } from '../../store/overlay/selectors';
import Joystick from '../components/joystick/joystick';
import TimerDisappearance from '../components/wrappers/timerDisappearance';
import { Canvas } from "../components/canvas/canvas";
import useInitOverlay from "../hooks/useInitOverlay";
import RenderEvent from "../components/overlays/renderEvent";
import getAvailableFeature from "../../domain/repositories/availableFeature";
import useGameInfo from "../hooks/useGameInfo";

function Overlay() {
  useInitOverlay()
  
  const { events } = useSelector(selectOverlayState)
  const { organizer } = useGameInfo()
  const [featureIsAvailable, setAvailabilityFeatures] = useState<boolean>()
  
  useEffect(() => {
    const isDev = process.env.REACT_APP_ENVIRONMENT !== 'production';
    if (isDev) {
      setAvailabilityFeatures(true)
    } else {
      if (!organizer?.id) return
      getAvailableFeature(organizer.id).then(features => {
        setAvailabilityFeatures(features.OBS_INTEGRATION)
      })
    }
  }, [organizer?.id])
  
  useEffect(() => {
    const rootDiv = document.getElementById('root')
    const body = document.getElementsByTagName('body')[ 0 ]
    rootDiv?.setAttribute('style', 'background: unset')
    body?.setAttribute('style', 'background: unset')
  }, []);
  
  if (featureIsAvailable === false) {return (<h1>Подписка не активирована</h1>)}
  
  return (
    <div key={'overlay'} className={'d-flex justify-content-end'} style={{ width: '100%', height: '100%' }}>
      <div
        className={'d-flex justify-content-end'}
        style={{ background: 'unset', width: '100%', height: '100%', position: 'relative' }}
      >
        {<Canvas/>}
        {/*<FlagDisappearances active={activeOverlay === 'team0'} duration={time.second}>*/}
        {/*  <TeamComposition teamIndex={0}/>*/}
        {/*</FlagDisappearances>*/}
        {/*<FlagDisappearances active={activeOverlay === 'team1'} duration={time.second}>*/}
        {/*  <TeamComposition teamIndex={1}/>*/}
        {/*</FlagDisappearances>*/}
        {events.map((event) => {
          return (
            <TimerDisappearance duration={durations[ event.type ]} key={event.id}>
              <RenderEvent event={event}/>
            </TimerDisappearance>
          )
        })}
      </div>
      {featureIsAvailable && <Joystick key={'joystick'}/>}
    </div>
  );
}

export default Overlay;
