import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import history from "../../../../../ud-ui/components/fabric-canvas/addons/history";
import { GridLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { Pages } from "../../../../../navigation/domain/enums/pages";
import Canvas from "./canvas";
import { Button, Container, Header, MainBlock, RightBlock, TemplateName } from "./styles";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { Zoom } from "./zoom";
import useTemplates from "../../../hooks/useTemplates";
import useBoards from "../../../hooks/useBoards";
import {
  selectFabricManager,
  selectHasChange,
  selectIsInit,
  selectSavedScheme,
} from "../../../../store/editor/selectors";
import { Scheme } from "../../../../../ud-ui/components/fabric-canvas/types";
import _ from "lodash";
import { AppDispatch } from "../../../../../../store/store";
import { setHasChanges, setPreviewState } from "../../../../store/editor/editor";
import useSaveScheme from "../../../hooks/useSaveScheme";
import { toast } from "react-toastify";

function Content() {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { activeTemplate } = useTemplates()
  const { activeBoard, activeScheme, isProcessed } = useBoards()
  const hasChange = useSelector(selectHasChange)
  const savedScheme = useSelector(selectSavedScheme)
  const isInit = useSelector(selectIsInit)
  const manager = useSelector(selectFabricManager)
  const { setData } = useLocalStorage()
  const { saveScheme } = useSaveScheme()
  
  setData({
    scheme: activeScheme,
    boardId: activeBoard?.id,
    resolution: activeTemplate?.resolution,
  })
  
  useEffect(() => {
    const historySubscriber = {
      update: (state: Scheme) => {
        if (!manager) return
        if (!savedScheme) return;
        const isEqual = _.isEqual(state, savedScheme)
        dispatch(setHasChanges(!isEqual))
      },
    };
    
    history.onChangeState.subscribe(historySubscriber)
    
    return () => {
      history.onChangeState.unsubscribe(historySubscriber)
    }
  }, [dispatch, manager, savedScheme])
  
  const onClickSave = useCallback(() => {
    saveScheme().then(() => {
      toast.success('Табло успешно сохранено')
    }).catch(e => {
      console.error(e)
      toast.error('Не удалось сохранить')
    })
  }, [saveScheme]);
  const onGoPreview = useCallback(() => {
    if (!manager) {return}
    
    history.disableHistory()
    manager.canvas.discardActiveObject()
    const historyState = history.getHistory()
    const curScheme = manager.getScheme(true)
    dispatch(setPreviewState({
      curHistoryIndex: historyState.index,
      history: historyState.history,
      scheme: curScheme,
      resolution: manager.resolution.value,
      backgroundColor: manager.canvas.backgroundColor?.toString(),
      viewport: manager.canvas.viewportTransform ? [...manager.canvas.viewportTransform] : undefined,
    }))
    
    navigate(Pages.TEMPLATE.PREVIEW)
  }, [dispatch, manager, navigate]);
  
  return (
    <MainBlock className={'flex-center flex-column h-100 w-100'}>
      {!isInit && <GridLoader style={{ position: 'absolute' }}/>}
      <div style={{ visibility: isInit ? 'visible' : 'hidden' }}>
        <Header>
          <div>
            <TemplateName>
              {activeTemplate?.name ?? 'Неизвестный шаблон'}
            </TemplateName>
          </div>
          <RightBlock>
            <Button onClick={onGoPreview} color={'#FFF'}>Предосмотр</Button>
            <Button disabled={!hasChange} onClick={onClickSave} color={'#F5D956'}>
              {isProcessed ? (
                'Сохранение'
              ) : (
                hasChange ? (
                  'Сохранить'
                ) : (
                  'Сохранено'
                )
              )
              }
            </Button>
          </RightBlock>
        </Header>
        <Container>
          <Canvas/>
          <Zoom/>
        </Container>
      </div>
    </MainBlock>
  );
}

export default Content;
