import { Canvas, Group, IText, Object, StaticCanvas, Textbox } from "fabric/fabric-impl";
import { fabric } from "fabric";
import { FixedTextbox } from "../../../../core/supabase/components/FixedTextbox";

enum LastGameResultColors {
  WON = '#57B34F',
  DRAW = '#867D7DFF',
  LOST = '#EF3D3D',
}

export interface Player {
  name: string;
  number: number;
  avatar: string;
}

interface ReplaceTextOptions {
  leftPosition?: fabric.Point
  centerPosition?: fabric.Point
  opacity?: number
  adjustText?: boolean
  additionalScale?: { x: number, y: number }
}

type Scale = { scaleX: number, scaleY: number };

type Offset = fabric.Point;

interface TeamCompositionComponentsOptions {
  scale: Scale,
  offset: Offset,
  opacity?: number
  onLoaded?: (img: fabric.Image) => void
}

interface ReplaceTeamCompositionOptions {
  opacity?: number
  onLoaded?: (img: fabric.Image) => void
}

interface PlayerBlockOptions {
  opacity?: number
  onLoaded?: (img: fabric.Image) => void
}

export class Replacer {
  private canvas!: StaticCanvas | Canvas;
  
  init(canvas: StaticCanvas) {
    this.canvas = canvas
  }
  
  replaceImageByIndicator(indicator: string, url: string, opacity = 1, onLoaded?: (img: fabric.Image) => void) {
    const objects = this.getObjects(indicator);
    if (!objects) {return;}
    this.replaceImage(objects, url, opacity, onLoaded)
  }
  
  replaceImage(
    objects: Object[],
    url: string,
    opacity = 1,
    onLoaded?: (img: fabric.Image) => void,
    centerPos?: fabric.Point,
    additionalScale: { x: number, y: number } = { x: 1, y: 1 },
  ) {
    for (const object of objects) {
      this.canvas.remove(object);
      
      fabric.Image.fromURL(url, (img) => {
        if (!object.width) {
          console.warn({ event: 'width not found' });
          return;
        }
        if (!object.height) {
          console.warn({ event: 'height not found' });
          return;
        }
        img.opacity = opacity
        
        img.set({ left: (object.left ?? 0), top: (object.top ?? 0), selectable: false });
        if (img.width === undefined || img.height === undefined) {
          console.warn({ event: 'width or height img not found' });
          return;
        }
        
        const scale = Math.min(
          object.width * object.getObjectScaling().scaleX / img.width * additionalScale.x,
          object.height * object.getObjectScaling().scaleY / img.height * additionalScale.y,
        );
        img.scale(scale);
        
        const center = centerPos ?? object.getCenterPoint();
        const indicator = object.data?.name
        img.setPositionByOrigin(center, 'center', 'center');
        img.data = { name: indicator };
        
        this.canvas.add(img);
        this.canvas.renderAll()
        if (!onLoaded) return;
        onLoaded(img)
      })
    }
    
  }
  
  replaceTextByIndicator(indicator: string, text: string, opacity = 1) {
    const objects = this.getObjects(indicator)
    if (!objects) {return;}
    
    this.replaceText(objects, text, { opacity })
  }
  
  replaceText(objects: Object[], text: string, options: ReplaceTextOptions = {}) {
    const { opacity = 1, leftPosition, centerPosition, adjustText = true, additionalScale = { x: 1, y: 1 } } = options;
    const result: Object[] = []
    for (const object of objects) {
      if (!object.width || !object.height) {
        console.warn({ event: 'width or height not found' });
        continue
      }
      if (object.type !== 'group') continue;
      const textboxOld = (object as Group).getObjects()[ 0 ] as Textbox
      this.canvas.remove(object)
      const indicator = object.data?.name
      const newTextBox = new fabric.IText(text, {
        left: object.left,
        fontWeight: object?.data?.fontWeight ?? 'normal',
        fontSize: object?.data?.fontSize ?? 20,
        fontFamily: object?.data?.fontFamily ?? 'Arial',
        textAlign: object?.data?.textAlign ?? 'center',
        fill: object?.data?.color ?? textboxOld.fill ?? '#000000FF',
        opacity: opacity,
        data: { name: indicator },
      })
      
      object.scaleX ? object.scaleX *= additionalScale.x : object.scaleX = additionalScale.x
      object.scaleY ? object.scaleY *= additionalScale.y : object.scaleY = additionalScale.y
      
      adjustText && this.adjustText(newTextBox, object as Group)
      switch (object.data.textAlign ?? 'center') {
        case 'left':
          const left = object.getPointByOrigin('left', 'center')
          newTextBox.setPositionByOrigin(leftPosition ?? left, 'left', 'center')
          break
        case 'right':
          const right = object.getPointByOrigin('right', 'center')
          newTextBox.setPositionByOrigin(right, 'right', 'center')
          break
        case 'center':
          const center = object.getCenterPoint()
          newTextBox.setPositionByOrigin(centerPosition ?? center, 'center', 'center')
          break
        case 'justify':
          newTextBox.setPositionByOrigin(object.getCenterPoint(), 'center', 'center')
          break;
      }
      
      this.canvas.add(newTextBox)
      this.canvas.renderAll();
      result.push(newTextBox);
    }
    
    return result;
  }
  
  replaceLastResultGame(indicator: string, results: (keyof typeof LastGameResultColors)[] | undefined, opacity = 1) {
    const objects = this.getObjects(indicator)
    if (!objects) return;
    const notInit = !results
    
    for (const object of objects) {
      this.canvas.remove(object);
      if (notInit) continue;
      const bounding = object.getBoundingRect(true)
      const radius = Math.min(bounding.height, bounding.width / 3) / 2
      const gap = (bounding.width - ((radius * 2) * 3)) / 2
      const circles = []
      for (let i = 0; i < 3; i++) {
        const circle = new fabric.Circle({
          left: bounding.left + (radius * 2) * i + gap * i,
          top: bounding.top,
          radius,
          fill: LastGameResultColors[ results[ i ] ],
          strokeWidth: 1,
          selectable: false,
          opacity: opacity,
        })
        circles.push(circle)
        this.canvas.add(circle)
      }
    }
  }
  
  private getObjects(indicator: string, teamIndex?: 0 | 1) {
    if (!this.canvas) {
      console.error('Canvas not found');
      return;
    }
    const objects = this.canvas.getObjects().filter((obj) => {
      const name = obj?.data?.name === indicator
      const team = teamIndex !== undefined ? obj?.data?.team === teamIndex : true
      return name && team
    });
    if (!objects.length) {
      return
    }
    return objects;
  }
  
  updateText(indicator: string, text: string) {
    const objects = this.getObjects(indicator)
    if (!objects) return;
    
    for (const object of objects) {
      if (object.type !== 'i-text') continue;
      (object as Textbox).set({ text });
    }
    
    this.canvas.renderAll()
  }
  
  adjustText(textbox: IText | Textbox, placeholder: Group) {
    if (placeholder.scaleX === undefined || placeholder.scaleY === undefined) return;
    if (placeholder.width === undefined || placeholder.height === undefined) return;
    if (textbox.scaleX === undefined || textbox.scaleY === undefined) return;
    if (textbox.width === undefined || textbox.height === undefined) return;
    const width = placeholder.getScaledWidth()
    const height = placeholder.getScaledHeight()
    if (!textbox.width || !textbox.height) return;
    textbox.set({ width: width, fontSize: height })
    
    if (textbox.width > width || textbox.height > height) {
      const factorScaleX = placeholder.width * placeholder.scaleX / textbox.width
      const factorScaleY = placeholder.height * placeholder.scaleY / textbox.height
      const factorScale = Math.min(factorScaleX, factorScaleY)
      textbox.set({ scaleX: factorScale, scaleY: factorScale })
    }
  }
  
  replaceTeamComposition(players: Player[], teamIndex: 0 | 1, options?: ReplaceTeamCompositionOptions) {
    const { opacity = 1, onLoaded } = options ?? {}
    const result = this.getObjects("teamComposition", teamIndex) as Group[]
    if (!result) return;
    if (!result.length) return;
    
    for (const teamComposition of result) {
      this.canvas.remove(teamComposition)
      if (!teamComposition || teamComposition.type !== 'group') continue;
      
      const objects = teamComposition.getObjects()
      if (!objects) continue;
      
      for (let i = 0; i < objects.length; i++) {
        const object = objects[ i ]
        const name = object?.data?.name
        if (!name) continue;
        
        switch (name) {
          case 'playerBlock': {
            const index = object.data?.number
            if (players[ index ] === undefined) break
            this.createPlayerBlock(object as Group, players[ index ], teamComposition, { opacity, onLoaded })
            break
          }
        }
      }
    }
    
    this.canvas.renderAll()
  }
  
  private createAvatar(avatar: Object, url: string, options: TeamCompositionComponentsOptions) {
    const { scale, offset, opacity, onLoaded } = options
    
    const left = avatar.getPointByOrigin('left', 'top')
    const pos = (new fabric.Point(left.x * scale.scaleX, left.y * scale.scaleY)).add(offset);
    const center = avatar.getPointByOrigin('center', 'center')
    const centerPos = (new fabric.Point(center.x * scale.scaleX, center.y * scale.scaleY)).add(offset);
    
    const react = new fabric.Rect({
      left: pos.x,
      top: pos.y,
      width: (avatar.width ?? 0) * scale.scaleX - scale.scaleX / 2, // - scale.scaleX / 2 иначе бортик вылазивает наполовину
      height: (avatar.height ?? 0) * scale.scaleY - scale.scaleY / 2, // - scale.scaleY / 2 иначе бортик вылазит наполовину
      fill: "#1A1A29",
      strokeWidth: scale.scaleX,
      stroke: "#404861",
      opacity,
    })
    this.canvas.add(react)
    this.replaceImage(
      [avatar],
      url,
      opacity,
      onLoaded,
      centerPos,
    )
  }
  
  private createNumber(number: Object, text: string, options: TeamCompositionComponentsOptions) {
    const { scale, offset, opacity } = options
    
    const left = number.getPointByOrigin('left', 'top')
    const scalingLeft = left.setXY(left.x * scale.scaleX, left.y * scale.scaleY);
    const pos = scalingLeft.add(offset);
    const center = number.getPointByOrigin('center', 'center')
    const scalingCenter = center.setXY(center.x * scale.scaleX, center.y * scale.scaleY);
    const centerPos = scalingCenter.add(offset);
    
    const react = new fabric.Rect({
      left: pos.x,
      top: pos.y,
      width: (number.width ?? 0) * scale.scaleX - scale.scaleX / 2,
      height: (number.height ?? 0) * scale.scaleY - scale.scaleY / 2,
      fill: "#1A1A29",
      strokeWidth: scale.scaleX,
      stroke: "#404861",
      opacity: opacity,
    })
    this.canvas.add(react)
    
    this.replaceText(
      [number],
      text,
      {
        opacity: opacity,
        centerPosition: centerPos.add({ x: 0, y: 2 * scale.scaleY }),
        additionalScale: { x: scale.scaleX, y: scale.scaleY },
      },
    )
  }
  
  private createName(name: Object, text: string, options: TeamCompositionComponentsOptions) {
    const { scale, offset, opacity } = options
    
    const leftForBackground = name.getPointByOrigin('left', 'top')
    const scalingLeftBackground = leftForBackground.setXY(leftForBackground.x * scale.scaleX, leftForBackground.y * scale.scaleY);
    const posForBackground = scalingLeftBackground.add(offset);
    const react = new fabric.Rect({
      left: posForBackground.x,
      top: posForBackground.y,
      width: (name.width ?? 0) * scale.scaleX,
      height: (name.height ?? 0) * scale.scaleY,
      fill: "#1A1A29",
      strokeWidth: 0,
      opacity: opacity,
    })
    this.canvas.add(react)
    
    const fixedTextbox = new FixedTextbox(text, {
      width: (name.width ? name.width * scale.scaleX : 0) - 20 * scale.scaleX,
      height: (name.height ? name.height * scale.scaleY : 0),
      fontSize: name.data?.fontSize * scale.scaleX ?? 20 * scale.scaleX,
      fill: name.data?.color ?? '#000000',
      fontFamily: name.data?.fontFamily ?? 'Big Shoulders Display, sans-serif',
      fontWeight: name.data?.fontWeight ?? '500',
      opacity: opacity,
    })
    
    const left = name.getPointByOrigin('left', 'center')
    const scalingLeft = left.setXY(left.x * scale.scaleX, left.y * scale.scaleY);
    const pos = scalingLeft.add(offset.add({ x: 10 * scale.scaleX, y: 0 }));
    fixedTextbox.setPositionByOrigin(pos, 'left', 'center')
    
    this.canvas.add(fixedTextbox)
  }
  
  private createPlayerBlock(group: Group, player: Player, teamComposition: Group, options: PlayerBlockOptions) {
    const { opacity, onLoaded } = options
    const avatarIndicator = 'playerAvatar'
    const numberIndicator = 'playerNumber'
    const nameIndicator = 'playerName'
    
    const avatar = group.getObjects().find((obj) => obj?.data?.name === avatarIndicator)
    const number = group.getObjects().find((obj) => obj?.data?.name === numberIndicator)
    const name = group.getObjects().find((obj) => obj?.data?.name === nameIndicator)
    const scale = teamComposition.getObjectScaling()
    const mainOffset = teamComposition.getPointByOrigin('center', "center");
    const blockOffset = group.getPointByOrigin('center', "center");
    const offset = mainOffset.add(blockOffset.setXY(blockOffset.x * scale.scaleX, blockOffset.y * scale.scaleY));
    
    if (avatar) {
      this.createAvatar(avatar, player.avatar, { offset, scale, opacity, onLoaded })
    }
    
    if (number) {
      this.createNumber(number, player.number.toString(), { offset, scale, opacity })
    }
    
    if (name) {
      this.createName(name, player.name, { offset, scale, opacity })
    }
  }
}
