import { createAsyncThunk } from "@reduxjs/toolkit";
import { Calendar } from "../domain/interfaces/calendar";
import TournamentRepository from "../domain/repositories/TournamentRepository";
import { getChampionshipsIdByDate } from "../domain/utils/getChampionshipsIdByDate";

const PREFIX = 'schedule';


export const fetchCalendar = createAsyncThunk<{ calendar: Calendar }, { tournamentId: number, monthNum: number }>(
  `${PREFIX}/fetchCalendar`,
  async (payload, { rejectWithValue }) => {
    try {
      const championships = await TournamentRepository.fetchMatchDates(payload.tournamentId)
      const championshipIds = getChampionshipsIdByDate(championships, payload.monthNum)
      const calendar = await TournamentRepository.fetchChampionships(championshipIds)
      return {
        calendar,
      }
    } catch (error) {
      console.error(`${PREFIX}/fetchCalendar error:`, error);
      
      let message
      if (error instanceof Error) message = error.message
      else message = String(error)
      return rejectWithValue(message)
    }
  });
