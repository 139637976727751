import { RealtimeChannel, SupabaseClient } from '@supabase/supabase-js';
import { ObservableField } from "../ObservableField";
import { NewReceivedData, ReceivedData, ReceivedEvent } from "../../domain/interfaces/supabase/receivedData";
import { Event } from "../../../stream/domain/interfaces/events";
import { durations } from "../../../stream/domain/constants/time";

type ConnectionType = "CLOSED" | "SUBSCRIBED" | "TIMED_OUT" | "CHANNEL_ERROR";

type TimeState = {
  seconds: number,
  period: number,
  // isStart: boolean
};

export class MatchChronology {
  constructor(private supabase: SupabaseClient) {}
  
  chanelEvents?: RealtimeChannel
  connectionStatus: ObservableField<ConnectionType> = new ObservableField<ConnectionType>("CLOSED")
  
  lastEvent?: Event
  events: ObservableField<Event[]> = new ObservableField<Event[]>([])
  lastDateUpdate?: Date = undefined
  activeBoardId: ObservableField<number | null> = new ObservableField<number | null>(null)
  currentTime: ObservableField<TimeState> = new ObservableField<TimeState>({
    seconds: 0,
    period: 0,
    // isStart: false,
  })
  
  public handleRecordUpdated(data: ReceivedData) {
    console.debug({ event: 'new data received', data })
    const date = new Date(data.commit_timestamp)
    if (this.lastDateUpdate === date) return
    this.lastDateUpdate = date
    
    this.checkEvents(data, date)
    this.checkActiveTemplate(data)
    this.checkTime(data)
  }
  
  public async observeChanges(matchId: number) {
    this.chanelEvents = this.supabase
      .channel(`room${matchId}`)
      // @ts-ignore
      .on('postgres_changes', {
        event: 'UPDATE',
        schema: 'public',
        table: 'match_chronology',
        filter: `matchId=eq.${matchId}`,
      }, this.handleRecordUpdated.bind(this))
      .subscribe((status, err) => {
        console.debug({ status })
        err && (console.error({ err }))
        this.connectionStatus.value = status
      }, 2000)
  }
  
  public async stopObserveChanges() {
    if (this.chanelEvents) await this.chanelEvents.unsubscribe()
  }
  
  public async getRow(matchId: number): Promise<NewReceivedData[] | null> {
    const { data, error } = await this.supabase
      .from('match_chronology')
      .select()
      .eq('matchId', matchId)
    
    if (!error) return data
    console.error(error)
    return null
  }
  
  private checkEvents(data: ReceivedData, date: Date) {
    const chronology: ReceivedEvent[] = data.new.chronology.filter(event => {
      const now = Date.now()
      return now - event.id < durations[ event.type ]
    })
    chronology.sort((a, b) => a.id - b.id)
    const lastEvent: ReceivedEvent = chronology[ chronology.length - 1 ]
    
    const clearEvent = this.events.value.filter((event) => {
      const now = Date.now()
      return now - event.updateAt < durations[ event.type ]
    })
    
    if (!lastEvent) return
    if (this.lastEvent && this.lastEvent?.id === lastEvent?.id) return
    
    this.lastEvent = { ...lastEvent, updateAt: date.getTime() }
    this.events.value = [...clearEvent, { ...lastEvent, updateAt: date.getTime() }]
  }
  
  private checkActiveTemplate(data: ReceivedData) {
    this.activeBoardId.value = data.new.templateId
  }
  
  private checkTime(data: ReceivedData) {
    const { seconds, currentPeriod: period } = data.new
    this.currentTime.value = {
      ...this.currentTime.value,
      seconds,
      period,
    }
  }
}
