import { RootState } from "../../../../store/store";
import { createSelector } from "@reduxjs/toolkit";

const selectState = (state: RootState) => state.templateEditor.templates
export const selectTemplates = createSelector(selectState, (state) => state.templates)
export const selectActiveTemplate = createSelector(
  selectState,
  (state) => state.templates.find(template => template.id === state.activeTemplateId),
)
export const selectIsLoading = createSelector(selectState, (state) => state.isLoading)
export const selectIsLoaded = createSelector(selectState, (state) => state.isLoaded)
export const selectIsProcessed = createSelector(selectState, (state) => state.isProcessed)
export const selectIsFinished = createSelector(selectState, (state) => state.isFinished)
export const selectActiveBoard = createSelector(
  [selectState, selectActiveTemplate],
  (state, activeTemplate) => {
    return activeTemplate?.boards.find(board => board.id === state.activeBoardId)
  })
export const selectBoards = createSelector(
  selectActiveTemplate,
  (activeTemplate) => activeTemplate?.boards ?? [],
)
