import React from 'react';
import { Event } from '../../../domain/interfaces/events';
import Card from './card';
import Goal from './goal'
import useGameInfo from "../../hooks/useGameInfo";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/store";

interface IProps {
  event: Event
}

function RenderEvent(props: IProps) {
  const { event } = props
  const { organizer, teams, goals } = useGameInfo()
  
  const leagueLogo = organizer?.logo
  const teamIndex = teams?.first?.id === event.teamId ? 0 : 1
  const teamPostfix = teamIndex === 0 ? 'first' : 'second'
  const team = teamIndex === 0 ? teams?.first : teams?.second
  
  const teamLogo = team?.logo
  const firstPlayer = event.players[ 0 ]
    ? (teams?.first.members?.concat(teams?.second.members ?? []) ?? []).find((member) => member.id === event?.players[ 0 ]?.id)
    : null
  const secondPlayer = event.players[ 1 ]
    ? (teams?.second?.members?.concat(teams?.first.members ?? []) ?? []).find((member) => member.id === event?.players[ 1 ]?.id)
    : null
  
  switch (event.type) {
    case 'PENALTY_GOAL':
    case 'GOAL':
      if (!firstPlayer) return null
      if (!teamLogo) return null
      if (!leagueLogo) return null
      // dispatch(setGoals({ ...goals, [ teamPostfix ]: goals?.[ teamPostfix ] + 1 }))
      return <Goal key={event.id} data={{
        teamLogo,
        leagueLogo,
        minute: event.minutes,
        score: goals?.[ teamPostfix ],
        lastName: firstPlayer.surname,
      }}/>
    case 'PASS_AND_GOAL':
      if (!secondPlayer) return null
      if (!teamLogo) return null
      if (!leagueLogo) return null
      return <Goal key={event.id} data={{
        teamLogo,
        leagueLogo,
        minute: event.minutes,
        score: goals?.[ teamPostfix ],
        lastName: secondPlayer.surname,
      }}/>
    case 'RED_CARD':
      if (!firstPlayer) return null
      if (!teamLogo) return null
      if (!leagueLogo) return null
      return <Card key={event.id} data={{
        teamLogo,
        leagueLogo,
        minute: event.minutes,
        cards: firstPlayer.qtyRedCardsMatch,
        lastName: firstPlayer.surname,
      }} color={'red'}
      />
    case 'YELLOW_CARD':
      if (!firstPlayer) return null
      if (!teamLogo) return null
      if (!leagueLogo) return null
      return <Card key={event.id} data={{
        teamLogo,
        leagueLogo,
        minute: event.minutes,
        cards: firstPlayer.qtyYellowCardsMatch,
        lastName: firstPlayer.surname,
      }} color={'yellow'}
      />
    default:
      return null
  }
}

export default RenderEvent;
