import React, { useCallback, useMemo } from 'react';
import NewEditIcon from "../../../../ud-ui/components/icon/new-edit-icon";
import NewTrashIcon from "../../../../ud-ui/components/icon/new-trash-icon";
import { setActiveTemplate } from "../../../store/templates/templates";
import { history } from "../../../../../store/store";
import { Pages } from "../../../../navigation/domain/enums/pages";
import { useDispatch, useSelector } from "react-redux";
import { TemplateExtend } from "../../../../core/domain/interfaces/supabase/overlay/auxiliary-types";
import useEditModal from "../../hooks/modals/template/edit";
import useDeleteModal from "../../hooks/modals/template/delete";
import styled from "@emotion/styled";
import { selectTemplates } from "../../../store/templates/selectors";
import { useOrganizer } from "../../../../organizer/ui/hooks/useOrganizer";
import useDuplicateModal from "../../hooks/modals/template/dublicate";
import EmptyButton from "../../../../ud-ui/components/emptyButton";
import Duplicate from "../../../../ud-ui/components/icon/icons/dublicate";

interface IProps {
  template: TemplateExtend
  index: number
}

function TemplateItem(props: IProps) {
  const dispatch = useDispatch()
  const { info } = useOrganizer()
  const { template, index } = props
  const isFeatureAvailable = useMemo(() => {
    const isDev = process.env.REACT_APP_ENVIRONMENT !== 'production'
    if (isDev) return true
    if (!info) return false
    return info.availableFeatures.OBS_INTEGRATION
  }, [info]);
  
  const templates = useSelector(selectTemplates)
  const { modal: deleteModal, open: openDeleteModal } = useDeleteModal()
  const { modal: editModal, open: openEditModal } = useEditModal()
  const { modal: duplicateModal, open: openDuplicateModal } = useDuplicateModal()
  
  const onClick = useCallback(() => {
    dispatch(setActiveTemplate({ id: template.id }))
    history.push(Pages.TEMPLATE.CHOOSING)
  }, [dispatch, template.id])
  
  const onRename = useCallback(() => {
    dispatch(setActiveTemplate({ id: template.id }))
    openEditModal()
  }, [dispatch, openEditModal, template.id])
  
  const onDelete = useCallback(() => {
    dispatch(setActiveTemplate({ id: template.id }))
    openDeleteModal()
  }, [dispatch, openDeleteModal, template.id])
  
  const onDuplicate = useCallback(() => {
    dispatch(setActiveTemplate({ id: template.id }))
    openDuplicateModal()
  }, [dispatch, openDuplicateModal, template.id])
  
  const isDefault = useMemo(() => {
    return template.access === 'default'
  }, [template.access]);
  
  const isCanDelete = useMemo(() => {
    return templates.length > 1 && !isDefault && isFeatureAvailable
  }, [isDefault, isFeatureAvailable, templates.length]);
  
  const isCanDuplicate = useMemo(() => {
    return isFeatureAvailable
  }, [isFeatureAvailable])
  
  return (
    <CategoryItem
      key={template.name}
      style={{ backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F7F7F7' }}
      className={'d-flex py-5 px-6 justify-content-between'}
    >
      <EmptyButton style={{ fontSize: 20 }} onClick={onClick}>{template.name}</EmptyButton>
      <div className={'d-flex'} style={{ gap: 24 }}>
        <EmptyButton
          icon={<Duplicate width={24} height={24}/>}
          disabled={!isCanDuplicate}
          onClick={onDuplicate}
        />
        <EmptyButton
          icon={<NewEditIcon width={24} height={24}/>}
          disabled={isDefault}
          onClick={onRename}
        />
        <EmptyButton
          color={'#FF6E6E'}
          icon={<NewTrashIcon width={24} height={24}/>}
          disabled={!isCanDelete}
          onClick={onDelete}
        />
      </div>
      {deleteModal}
      {editModal}
      {duplicateModal}
    </CategoryItem>
  );
}

export default TemplateItem;

const CategoryItem = styled.div`
  font-family: SF Pro Text, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`
