import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const increaseClickArea = css`
  position: relative;
  
  &:after {
    content: "";
    position: absolute;
    width: 40px;
    height: 100px;
  }
`

export const MainBlock = styled.div`
  padding-top: 60px;
  padding-inline: 24px;
  display: flex;
  height: 100%;
  padding-bottom: 30px;
`

export const TemplateListCatalog = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 340px);
  justify-content: space-between;
  grid-auto-flow: row;
  grid-row-gap: 20px;
  margin-top: 40px;
  
  padding-inline: 24px;
  padding-bottom: 8px;
  height: calc(100% - 124px);
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #F4F4F5;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 8px;
  }
`
