import { TemplateExtend } from "../../../../core/domain/interfaces/supabase/overlay/auxiliary-types";
import { ButtonWatch, CanvasWrapper, TemplateItemWrapper } from "./styles";
import UDText from "../../../../ud-ui/components/text";
import Likes from "../../../../ud-ui/components/icon/likes";
import Viewers from "../../../../ud-ui/components/icon/viewers";
import useFabric from "../../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import { useCallback, useEffect, useState } from "react";
import { history } from "../../../../../store/store";
import { Pages } from "../../../../navigation/domain/enums/pages";

interface IProps {
  template: TemplateExtend
}

function TemplateItem(props: IProps) {
  const { template } = props
  const [isHover, setIsHover] = useState(false)
  const { manager, canvas } = useFabric("static", { id: template.id.toString(), data: { ...template.resolution } });
  
  useEffect(() => {
    if (!manager) return
    
    const resizing = () => {
      manager.setParentSize()
      manager.normalizedViewport()
    }
    
    if (!template.boards[ 0 ]) return
    const board = template.boards[ 0 ]
    const scheme = { objects: board.objects, version: board.version }
    manager.loadLocal({ scheme: scheme, safeBackground: true })
    
    resizing()
    window.addEventListener('resize', resizing)
    return () => {
      window.removeEventListener('resize', resizing)
    }
  }, [manager, template])
  
  const onClick = useCallback(() => {
    history.push(Pages.TEMPLATE.CATALOG.builders.card(template.id))
  }, [template.id]);
  
  return (
    <TemplateItemWrapper>
      <CanvasWrapper
        onClick={onClick}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {canvas}
        <ButtonWatch isShow={isHover}>
          Смотреть
        </ButtonWatch>
      </CanvasWrapper>
      <div className={'d-flex align-items-center justify-content-between'} style={{ marginTop: 8 }}>
        <UDText type={'caption-1'} style={{ color: '#1A1A29', fontWeight: 'bold' }}>{template.name}</UDText>
        <div className="flex-center" style={{ gap: 14, color: '#9093AA' }}>
          <div className="flex-center" style={{ gap: 4 }}>
            <Likes/>
            <UDText type={'tip'} style={{ color: 'inherit' }}>{template.likes?.length}</UDText>
          </div>
          <div className="flex-center" style={{ gap: 4 }}>
            <Viewers/>
            <UDText type={'tip'} style={{ color: 'inherit' }}>{template.views?.length}</UDText>
          </div>
        </div>
      </div>
      <UDText type={'tip'} style={{ color: '#9093AA', marginTop: 2 }}>{template.ownerId ?? 'ImGame'}</UDText>
    </TemplateItemWrapper>
  );
}

export default TemplateItem;
