import { RootState } from "../../../../store/store";
import { createSelector } from "@reduxjs/toolkit";

const selectCatalog = (state: RootState) => state.templateEditor.catalog;

export const selectTemplates = createSelector(selectCatalog, (catalog) => catalog.templates);
export const selectSortByDate = createSelector(selectCatalog, (catalog) => catalog.sortByDate);
export const selectFilterByName = createSelector(selectCatalog, (catalog) => catalog.filterByName);
export const selectTemplatesByFilter = createSelector(
  [selectCatalog, selectTemplates],
  (catalog, templates) => {
    const filter = catalog.filterByName.toLowerCase()
    const filteredTemplates = filter ? templates.filter((template) => template.name.toLocaleLowerCase().includes(filter)) : [...templates]
    switch (catalog.sortByDate) {
      case 'desc':
        return filteredTemplates.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      case 'asc':
        return filteredTemplates.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      default:
        return filteredTemplates
    }
  },
)
