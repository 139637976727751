/** @jsxImportSource @emotion/react */
import UDTooltip from "../../../../../ud-form/components/tooltip";
import { IconButton, tooltipStyles } from "./styles";
import TextIcon from "../../../../../ud-ui/components/icon/text-icon";
import React from "react";
import InteractiveClickableIcon from "./interactiveClickableIcon";
import history from "../../../../../ud-ui/components/fabric-canvas/addons/history";
import { fabric } from "fabric";
import customization from "../../../../../ud-ui/components/fabric-canvas/addons/customization";
import { useSelector } from "react-redux";
import { selectActiveModal, selectFabricManager } from "../../../../store/editor/selectors";

export function AddText() {
  const activeModal = useSelector(selectActiveModal);
  const fabricManager = useSelector(selectFabricManager);
  
  const addText = (text: string) => {
    if (!fabricManager) return
    history.saveState()
    const { left, top } = fabricManager.getLeftTopCurVP();
    
    let TextBox = new fabric.Textbox(text, {
      fontSize: 20,
      fontFamily: 'Open Sans, sans-serif',
      left,
      top,
      strokeLineJoin: 'round',
      strokeWidth: 0,
      fontWeight: 'normal',
      fill: '#000000FF',
      ...customization.getAllProperties(),
    });
    
    TextBox.on('editing:exited', () => {
      if (TextBox.text !== undefined && TextBox.text.replace(/\s+/g, ' ').trim() !== '') return;
      fabricManager.canvas.remove(TextBox)
    })
    
    fabricManager.canvas.add(TextBox)
  }
  
  const onClickAddText = () => {
    if (!fabricManager) return
    addText('Новый текст')
  }
  
  return (
    <UDTooltip show={!activeModal} css={tooltipStyles} text={"Текст"}>
      <IconButton onClick={onClickAddText}>
        <InteractiveClickableIcon child={TextIcon}/>
      </IconButton>
    </UDTooltip>
  );
}
