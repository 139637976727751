import React, { useCallback, useEffect, useMemo } from 'react';
import UDMainLayout from "../../../../ud-ui/layout/main";
import UDBreadcrumbs from "../../../../ud-ui/components/breadcrumbs";
import { Pages } from "../../../../navigation/domain/enums/pages";
import DataPicker from "../../components/dataPicker";
import { GamesWrapper, MainBlock } from "./styles";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { fetchCalendar } from "../../../store/actions";
import { useOrganizer } from "../../../../organizer/ui/hooks/useOrganizer";
import { useSchedule } from "../../hooks/useSchedule";
import { StyledGameCard, StyledGameDateHeader } from "../../../../games/ui/components/games-list/games-list";
import { format } from "date-fns";
import ru from "date-fns/locale/ru";
import { useNavigate } from "react-router-dom";
import adImage from "../../../../ud-ui/components/images/ad.png";
import UDText from "../../../../ud-ui/components/text";


function Calendar() {
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const { info, isLoading: isLoadingOrganizer } = useOrganizer()
  const { isLoading: isLoadingSchedule, selectDay, currentDate } = useSchedule()
  const breadcrumb = useMemo(() => (<UDBreadcrumbs className="my-10" breadcrumbs={[
    { label: 'Расписание', to: Pages.SCHEDULE.INDEX },
  ]}/>), [])
  
  useEffect(() => {
    if (!info) return
    dispatch(fetchCalendar({ tournamentId: info.id, monthNum: currentDate.month }))
  }, [currentDate.month, dispatch, info]);
  
  const dateKey = useMemo(() => {
    return format(selectDay.date, 'dd MMMM, EEEEEE', { locale: ru })
  }, [selectDay.date]);
  
  const onShowGameTemplatesClick = useCallback((id: number) => {
    navigate(Pages.SCHEDULE.builders.view(id))
  }, [navigate]);
  
  return (
    <UDMainLayout headerContent={breadcrumb}>
      <MainBlock>
        <div className={'d-flex'} style={{ height: 279, gap: 30, justifyContent: "space-between" }}>
          <DataPicker/>
          <div>
            <img src={adImage} alt={'Здесь может быть ваша реклама'}
                 style={{ objectFit: 'cover', width: '100%', height: '100%', borderRadius: 8 }}/>
          </div>
        </div>
        {!(isLoadingOrganizer || isLoadingSchedule) && (
          selectDay.matches.length > 0 ? (
            <div className="mb-6 last-mb-0" style={{ overflow: 'hidden' }}>
              <StyledGameDateHeader>
                {dateKey}
              </StyledGameDateHeader>
              <GamesWrapper>
                {selectDay.matches.map((game) => (
                  <StyledGameCard
                    key={game.id}
                    game={game}
                    onShowTemplatesClick={onShowGameTemplatesClick}
                  />
                ))}
              </GamesWrapper>
            </div>
          ) : (
            <div className={'flex-center'}
                 style={{ flexDirection: 'column', backgroundColor: 'white', paddingBlock: 26, borderRadius: 8 }}>
              <UDText type={'callout'} className={'color-SurfaceRomanSilver30'} style={{ fontSize: 15 }}>
                На эти даты матчей нет.
              </UDText>
              <UDText type={'caption-1'}>
                <a style={{ color: '#007AFF' }} target={'_blank'} href={'https://imgameapp.ru/#contacts'}
                   rel="noreferrer">Сообщить об ошибке</a>
              </UDText>
            </div>
          )
        )}
      </MainBlock>
    </UDMainLayout>
  );
}

export default Calendar;
