import { combineReducers } from '@reduxjs/toolkit';
import templates from "./templates/templates";
import editor from "./editor/editor";
import catalog from "./catalog/catalog";

const templateEditorReducer = combineReducers({
  templates: templates,
  editor: editor,
  catalog: catalog,
});

export default templateEditorReducer;
