/** @jsxImportSource @emotion/react */
import UDTooltip from "../../../../../ud-form/components/tooltip";
import { IconButton, tooltipStyles } from "./styles";
import TrashIcon from "../../../../../ud-ui/components/icon/editor-trash-icon";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import InteractiveClickableIcon from "./interactiveClickableIcon";
import { selectActiveModal, selectActiveObjects, selectFabricManager } from "../../../../store/editor/selectors";

export function Delete() {
  const activeModal = useSelector(selectActiveModal);
  const fabricManager = useSelector(selectFabricManager)
  const activeObjects = useSelector(selectActiveObjects)
  
  const onClickDelete = useCallback(() => {
    if (!fabricManager) return
    const activeObjects = fabricManager.canvas.getActiveObjects()
    fabricManager.canvas.discardActiveObject()
    fabricManager.canvas.remove(...activeObjects)
  }, [fabricManager]);
  
  useEffect(() => {
    const deleteOnDel = (event: KeyboardEvent) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        onClickDelete()
      }
    }
    
    document.addEventListener('keydown', deleteOnDel)
    return () => {
      document.removeEventListener('keydown', deleteOnDel)
    }
  }, [onClickDelete])
  
  return (
    <UDTooltip show={!activeModal} css={tooltipStyles} text={"Удалить"}>
      <IconButton onClick={onClickDelete}>
        <InteractiveClickableIcon child={TrashIcon} isActive={activeObjects.length > 0}/>
      </IconButton>
    </UDTooltip>
  );
}
