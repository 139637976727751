/** @jsxImportSource @emotion/react */
import UDTooltip from "../../../../../ud-form/components/tooltip";
import { IconButton, ModalButton, tooltipStyles } from "./styles";
import React, { useEffect, useState } from "react";
import InteractiveToggleIcon from "./interactiveToggleIcon";
import RectanglePlusIcon from "../../../../../ud-ui/components/icon/rectangle-plus-icon";
import BaseModal from "../../elements/modals/baseModal";
import history from "../../../../../ud-ui/components/fabric-canvas/addons/history";
import elements from "../../../../../ud-ui/components/fabric-canvas/addons/elements";
import { useDispatch, useSelector } from "react-redux";
import { ModalState } from "../../../../domain/interface/modalState";
import { selectActiveModal, selectFabricManager } from "../../../../store/editor/selectors";
import { setActiveModal } from "../../../../store/editor/editor";
import { Divider } from "../../../../../ud-ui/components/divider";
import { Canvas, Object, StaticCanvas } from "fabric/fabric-impl";
import SnapToGrid from "../../../../../ud-ui/components/fabric-canvas/addons/snapToGrid";


export function AddPlaceholder() {
  const dispatch = useDispatch()
  const activeModal = useSelector(selectActiveModal);
  const fabricManager = useSelector(selectFabricManager);
  const [isSelected, setSelected] = useState<boolean>(false);
  const [modalState, setModalState] = useState<ModalState>({ isModalOpen: false, modalStyle: {} });
  
  
  useEffect(() => {
    const clickListener = () => {
      if (isSelected) {
        setSelected(false)
        setModalState((prev) => ({ ...prev, isModalOpen: false }))
      }
    };
    
    const closeModalOnEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setSelected(false)
        setModalState((prev) => ({ ...prev, isModalOpen: false }))
        dispatch(setActiveModal(undefined))
      }
    }
    
    document.addEventListener('keydown', closeModalOnEsc)
    document.addEventListener('click', clickListener)
    return () => {
      document.removeEventListener('keydown', closeModalOnEsc)
      document.removeEventListener('click', clickListener)
    }
  }, [activeModal, dispatch, isSelected])
  
  useEffect(() => {
    if (!isSelected && activeModal === 'addPlaceholder') {
      dispatch(setActiveModal(undefined))
    }
    // eslint-disable-next-line
  }, [isSelected]);
  
  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (isSelected) {
      setModalState((prev) => ({ ...prev, isModalOpen: false }))
      setSelected(false)
      dispatch(setActiveModal(undefined))
      return
    }
    
    const target = e.currentTarget;
    if (!target) return;
    
    const bounding = target.getBoundingClientRect();
    setModalState(
      {
        isModalOpen: true,
        modalStyle: {
          left: `${bounding.left + bounding.width + 15}px`,
          width: 300,
        },
      },
    )
    setSelected(true)
    dispatch(setActiveModal("addPlaceholder"))
  }
  
  const addPlaceholder = (text: string, blockName: string, type: string, hasTeam: boolean) => {
    if (!fabricManager) return
    history.saveState()
    if (type === 'text') {
      fabricManager.canvas.add(elements.createTextPlaceholder(fabricManager.canvas, text, {
        type,
        name: blockName,
        hasTeam,
      }))
    } else {
      fabricManager.canvas.add(elements.createPlaceholder(fabricManager.canvas, text, {
        type,
        name: blockName,
        hasTeam,
      }))
    }
    
    setModalState((prev) => ({ ...prev, isModalOpen: false }))
    dispatch(setActiveModal(undefined))
  }
  
  
  const placeholderButtons = placeholdersList.map((placeholder) => {
    return (
      <ModalButton
        key={placeholder.blockName}
        onClick={() => addPlaceholder(placeholder.text, placeholder.blockName, placeholder.type, placeholder.hasTeam)}>
        {placeholder.name}
      </ModalButton>
    )
  })
  
  const complexPlaceholderButtons = complexPlaceholdersList.map((placeholder) => {
    const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation()
      if (!fabricManager) return
      history.saveState()
      fabricManager.canvas.add(placeholder.function.bind(elements)(fabricManager.canvas))
      setModalState((prev) => ({ ...prev, isModalOpen: false }))
      dispatch(setActiveModal(undefined))
    }
    
    return (
      <ModalButton
        key={placeholder.name}
        onClick={onClick}>
        {placeholder.name}
      </ModalButton>
    )
  })
  
  return (
    <>
      <UDTooltip show={!activeModal} css={tooltipStyles} text={"Блоки"}>
        <IconButton onClick={onClick}>
          <InteractiveToggleIcon child={RectanglePlusIcon}/>
        </IconButton>
      </UDTooltip>
      <BaseModal isOpen={modalState.isModalOpen && isSelected} style={modalState.modalStyle}>
        {placeholderButtons}
        <Divider type={'horizontal'} color={'#CCCCCC'} style={{ paddingInline: 6 }}/>
        {complexPlaceholderButtons}
      </BaseModal>
    </>
  );
}

const placeholdersList: { text: string, blockName: string, name: string, type: string, hasTeam: boolean }[] = [
  { text: 'Город', blockName: 'city', name: 'Город', type: 'text', hasTeam: false },
  { text: 'Логотип турнира', blockName: 'organizerLogo', name: 'Логотип турнира', type: 'image', hasTeam: false },
  {
    text: 'Название чемпионата',
    blockName: 'championshipName',
    name: 'Название чемпионата',
    type: 'text',
    hasTeam: false,
  },
  {
    text: 'Время начала матча',
    blockName: 'startMatchTime',
    name: 'Время начала матча',
    type: 'text',
    hasTeam: false,
  },
  { text: 'Дата начала матча', blockName: 'startMatchDate', name: 'Дата начала матча', type: 'text', hasTeam: false },
  { text: 'Время матча', blockName: 'matchTime', name: 'Время матча', type: 'text', hasTeam: false },
  { text: 'Логотип\nкоманды №1', blockName: 'teamLogo0', name: 'Логотип команды', type: 'image', hasTeam: true },
  { text: 'Название команды №1', blockName: 'teamName0', name: 'Название команды', type: 'text', hasTeam: true },
  { text: 'Счёт команды №1', blockName: 'teamScore0', name: 'Счёт команды', type: 'text', hasTeam: true },
  { text: 'Фолы команды №1', blockName: 'teamFouls0', name: 'Фолы команды', type: 'text', hasTeam: true },
  {
    text: 'Последние результаты\nкоманды №1',
    blockName: 'lastGameResults0',
    name: 'Последние результаты команды',
    type: 'image',
    hasTeam: true,
  },
]

const complexPlaceholdersList: {
  function: (canvas: Canvas | StaticCanvas) => Object,
  hasTeam: boolean,
  name: string
}[] = [
  { function: elements.createTeamCompositionPlaceholder, hasTeam: true, name: 'Состав команды' },
]
