import { Button, Item, LongDivider, ShortDivider, SubItem } from "./styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Group } from "fabric/fabric-impl";
import { useSelector } from "react-redux";
import { selectFabricManager } from "../../../../store/editor/selectors";
import Input, { IconWrapper } from "../../elements/input";
import history from "../../../../../ud-ui/components/fabric-canvas/addons/history";
import { fabric } from "fabric";
import { round } from "../../../../domain/utils/helpers";
import SnapToGrid from "../../../../../ud-ui/components/fabric-canvas/addons/snapToGrid";
import layerManager from "../../../../../ud-ui/components/fabric-canvas/addons/layerManager";
import ArrowRightIcon from "../../../../../ud-ui/components/icon/arrow-right-icon";
import ChoosingTeam from "./components/choosingTeam";

interface IProps {
  object: Group
}

function ComplexPlaceholder(props: IProps) {
  const manager = useSelector(selectFabricManager)
  const { object } = props
  
  const data = useMemo(() => { return object.data }, [object.data])
  const point = useMemo(() => {
    const { x, y } = object.getPointByOrigin('left', 'top')
    return { x: round(x), y: round(y) }
  }, [object])
  const bounding = useMemo(() => {
    const { width, height } = object.getBoundingRect()
    return { width: round(width), height: round(height) }
  }, [object])
  
  const [hasTeam, setHasTeam] = useState(data.hasTeam ?? false)
  const [position, setPosition] = useState({ x: point.x, y: point.y })
  const [size, setSize] = useState(bounding)
  const [teamIndex, setTeamIndex] = useState(data?.team)
  
  useEffect(() => {
    object.setControlsVisibility({
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      bl: true,
      br: true,
      tl: true,
      tr: true,
      mtr: false,
    })
    
    setPosition(point)
    setSize(bounding)
  }, [bounding, object, point]);
  
  useEffect(() => {
    if (!manager) return
    manager.canvas.uniScaleKey = undefined
    
    return () => {
      manager.canvas.uniScaleKey = 'shiftKey'
    };
  }, [manager]);
  
  useEffect(() => {
    setHasTeam(data.hasTeam ?? false)
    if (data.hasTeam) setTeamIndex(data.team)
  }, [data.hasTeam, data.team])
  
  const onFocusField = useCallback(() => {
    manager?.render()
    history.saveState()
  }, [manager])
  
  const onChangePosition = useCallback((x: number, y: number) => {
    object.setPositionByOrigin(new fabric.Point(x, y), 'left', 'top')
    setPosition({ x: round(x), y: round(y) })
    manager?.render()
  }, [manager, object]);
  
  const onChangeSize = useCallback((width: number, height: number) => {
    object.set({ scaleX: width / (object?.width ?? 1), scaleY: height / (object?.height ?? 1) })
    setSize({ width: round(width), height: round(height) })
    SnapToGrid.transformAfterScaling(object)
    manager?.render()
  }, [manager, object]);
  
  const onChangeTeam = useCallback((teamIndex: 0 | 1) => {
    if (!object.data?.team === undefined) return
    object.data.team = teamIndex
    history.saveState()
    setTeamIndex(teamIndex)
  }, [object.data]);
  
  const showDebugInfo = true
  const isDev = useMemo(() => {
    return process.env.NODE_ENV === 'development'
  }, []);
  
  return (
    <>
      <Item>
        <div>Фигура</div>
      </Item>
      <ShortDivider/>
      <Item>
        <SubItem>
          <span>Расположение</span>
          <Input
            onFocus={onFocusField}
            style={{ width: 73 }}
            icon={<IconWrapper>X</IconWrapper>}
            value={position.x.toString()}
            onChange={(value) => onChangePosition(+value, position.y)}
          />
          <Input
            onFocus={onFocusField}
            style={{ width: 73 }}
            icon={<IconWrapper>Y</IconWrapper>}
            value={position.y.toString()}
            onChange={(value) => onChangePosition(position.x, +value)}
          />
        </SubItem>
        <SubItem>
          <span>Размер</span>
          <Input
            onFocus={onFocusField}
            icon={<IconWrapper>Ш</IconWrapper>}
            value={size.width.toString()}
            onChange={(value) => onChangeSize(+value, size.height)}
          />
          <Input
            onFocus={onFocusField}
            icon={<IconWrapper>В</IconWrapper>}
            value={size.height.toString()}
            onChange={(value) => onChangeSize(size.width, +value)}
          />
        </SubItem>
      </Item>
      {hasTeam && (
        <>
          <LongDivider/>
          <Item>
            <ChoosingTeam onChange={onChangeTeam} value={teamIndex}/>
          </Item>
        </>
      )}
      <LongDivider/>
      <Item>
        Слои
        <Button onClick={() => layerManager.sendToFront(object)}>
          <ArrowRightIcon height={16} width={16} color={'#929292'} direction={'up'}/> Поднять вверх
        </Button>
        <Button onClick={() => layerManager.sendToBack(object)}>
          <ArrowRightIcon height={16} width={16} color={'#929292'} direction={'down'}/> Переместить вниз
        </Button>
      </Item>
      {isDev && showDebugInfo && (
        <Item>
          data: <span style={{ whiteSpace: 'pre' }}>{JSON.stringify(object.data, null, 2)}</span>
        </Item>
      )}
    </>
  );
}

export default ComplexPlaceholder;
