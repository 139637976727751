import { BoardDTO } from "../interfaces/supabase/overlay/dto";
import { Board } from "../interfaces/supabase/overlay/entity";

const boardDataMapper = (board: BoardDTO): Board => {
  return {
    ...board,
    createdAt: new Date(board.created_at),
    templateId: board.template_id,
    isFavorite: board.is_favorite,
  }
};

export default boardDataMapper;
