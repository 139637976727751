import { fabric } from "fabric";

export class FixedTextbox extends fabric.Textbox {
  _wrapText(lines: string[], desiredWidth: number) {
    let _line = lines[ 0 ];
    const offset = 0;
    let textWidth = this._measureWord(_line.split(' '), 0, offset);
    
    if (textWidth > desiredWidth && !this.isEditing) {
      const ellipsis = '…';
      const lengthReducingFactor = desiredWidth / textWidth;
      let length = Math.ceil(_line.length * lengthReducingFactor);
      while (textWidth >= desiredWidth - 2 && length > 0) {
        const symbols = _line.substring(0, length--);
        _line = symbols[ symbols.length - 1 ] === ' ' ? symbols : symbols + ellipsis;
        textWidth = this._measureWord(_line.split(' '), 0, offset);
      }
    }
    
    return super._wrapText([_line], desiredWidth);
  }
}
