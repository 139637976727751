/** @jsxImportSource @emotion/react */
import React, { HTMLInputTypeAttribute } from 'react';
import { DivProps } from "../../../../../../../../typings";
import UDText from "../../../../../../../ud-ui/components/text";
import UDFormInput from "../../../../../../../ud-form/components/input";
import { InputCss } from "./styles";
import { useTheme } from "@emotion/react";
import { onlyNumber } from "../../../../../../../organizers/domain/utils/regx/onlyNumber";

interface IProps extends Omit<DivProps, 'onChange'> {
  name: string;
  text: string;
  type?: HTMLInputTypeAttribute
  readonly: boolean
}

function SizeItem(props: IProps) {
  const { name, type, readonly, ...rest } = props;
  const theme = useTheme()
  
  return (
    <div className={'d-flex flex-row align-items-center'} style={{ gap: '10px' }} {...rest}>
      <UDText type={'caption-1'} className={'color-Gray96'}>{props.text}</UDText>
      <UDFormInput
        readOnly={readonly}
        parser={onlyNumber}
        css={InputCss(theme)}
        name={name}
        type={type}
      />
    </div>
  );
}

export default SizeItem;
