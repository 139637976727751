import { IndicatorWrapper } from "./styles";
import UDText from "../../../../ud-ui/components/text";

interface IProps {
  icon?: JSX.Element
  text: string
  isActive: boolean
}

function Indicator(props: IProps) {
  const { icon, text, isActive } = props
  
  return (
    <IndicatorWrapper className={isActive ? 'active' : 'disable'}>
      {icon}
      <UDText style={{ color: 'inherit' }} type={'tip'}>{text}</UDText>
    </IndicatorWrapper>
  );
}

export default Indicator;
