import React from 'react';
import styled from '@emotion/styled';
import { Game } from '../../../domain/interfaces/Game';
import { groupGamesByDate } from '../../../../tourneys/ui/components/form-parts/games/helpers';
import GamesComponentsGameCard from '../card';
import classNames from 'classnames';

const StyledEmptyGames = styled.div`
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  color: ${({ theme }) => theme.colors.SurfaceRomanSilver30};
  margin-top: 30px;
`;

export const StyledGameCard = styled(GamesComponentsGameCard)`
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledGameDateHeader = styled.div`
  padding: 8px;
  background: ${({ theme }) => theme.colors.SurfacePlatinum20};
  color: ${({ theme }) => theme.colors.SurfaceRaisinBlack50};
  border-radius: 8px 8px 0px 0px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  text-transform: uppercase;
`;

type GamesListProps = {
  games: Game[];
  activeGameId?: number;
  onEditGameClick?: (game: Game) => void;
  onShowGameResultClick?: (gameId: number) => void;
  onShowGameTemplatesClick?: (gameId: number) => void;
  onDeleteGameClick?: (gameId: number) => void;
};

const GamesList = (props: GamesListProps) => {
  const {
    games,
    activeGameId,
    onEditGameClick,
    onShowGameResultClick,
    onShowGameTemplatesClick,
    onDeleteGameClick,
  } = props;

  if (!games.length) {
    return (
      <StyledEmptyGames>Список матчей пуст</StyledEmptyGames>
    );
  }

  const gameKeys = Object.entries(groupGamesByDate(games));

  return (
    <div>
      {gameKeys.map(([dateKey, groupGames]) => (
        <div className="mb-6 last-mb-0" key={dateKey}>
          <StyledGameDateHeader>
            {dateKey}
          </StyledGameDateHeader>
          {groupGames.map(({ game }) => (
            <StyledGameCard
              key={game.id}
              game={game}
              onEditClick={onEditGameClick}
              onShowResultClick={onShowGameResultClick}
              onShowTemplatesClick={onShowGameTemplatesClick}
              onDeleteClick={onDeleteGameClick}
              className={classNames('last-mb-0', {
                active: game.id === activeGameId,
              })}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default GamesList;
