import { ChampionshipDatesDTO } from "../interfaces/dtos";

export function getChampionshipsIdByDate(championships: ChampionshipDatesDTO[], monthNumber: number) {
  return championships
    .filter(championship => {
      return championship.matches.find(match => {
        if (!match.date) return false
        return new Date(match.date).getMonth() === monthNumber
      })
    })
    .map(championship => championship.id)
}
