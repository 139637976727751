import { useSelector } from "react-redux";
import { selectActiveBoard, selectBoards, selectIsFinished, selectIsProcessed } from "../../store/templates/selectors";
import { Scheme } from "../../../ud-ui/components/fabric-canvas/types";
import { useMemo } from "react";

function useBoards() {
  const boards = useSelector(selectBoards);
  const activeBoard = useSelector(selectActiveBoard)
  const isProcessed = useSelector(selectIsProcessed);
  const isFinished = useSelector(selectIsFinished);
  const activeScheme = useMemo(() => {
    return activeBoard ? {
      version: activeBoard.version,
      objects: activeBoard.objects,
    } : undefined as Scheme | undefined
  }, [activeBoard]);
  
  return ({
    boards,
    isProcessed,
    isFinished,
    activeBoard,
    activeScheme,
  });
}

export default useBoards;
