import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TemplateExtend, UpdateBoard } from "../../../core/domain/interfaces/supabase/overlay/auxiliary-types";
import {
  createCopyTemplateReducer,
  createCreateBoardReducer,
  createCreateTemplateReducer,
  createDeleteBoardReducer,
  createDeleteTemplateReducer,
  createFetchTemplatesReducer,
  createUpdateBoardReducer,
  createUpdateTemplateReducer,
} from "./reducers";

export type TemplatesSliceState = {
  templates: TemplateExtend[]
  activeTemplateId?: number
  activeBoardId?: number
  
  isLoading: boolean
  isLoaded: boolean
  isProcessed: boolean,
  isFinished: boolean,
};

const initialState: TemplatesSliceState = {
  templates: [],
  
  isLoaded: false,
  isLoading: false,
  isProcessed: false,
  isFinished: false,
};

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setActiveTemplate(state, action: PayloadAction<{ id: number }>) {
      state.activeTemplateId = action.payload.id
    },
    resetPressedState(state) {
      state.isProcessed = false
      state.isFinished = false
    },
    setActiveBoard(state, action: PayloadAction<{ id: number | undefined }>) {
      if (!state.activeTemplateId) return
      state.activeBoardId = action.payload.id
    },
    favoriteBoard(state, action: PayloadAction<{ id: number }>) {
      if (!state.activeTemplateId) return
      const activeTemplate = state.templates.find(template => template.id === state.activeTemplateId)
      if (!activeTemplate) return
      const board = activeTemplate.boards.find(board => board.id === action.payload.id)
      if (!board) return
      board.isFavorite = !board.isFavorite
    },
    updateBoard(state, action: PayloadAction<UpdateBoard>) {
      if (!state.activeTemplateId) return
      state.templates = state.templates.map(template => {
        if (template.id === state.activeTemplateId) {
          template.boards = template.boards.map(board => board.id === action.payload.id ? action.payload : board) as any
        }
        return template
      })
    },
  },
  extraReducers: (builder) => {
    createFetchTemplatesReducer(builder);
    createCreateTemplateReducer(builder);
    createDeleteTemplateReducer(builder);
    createUpdateTemplateReducer(builder);
    createCreateBoardReducer(builder);
    createDeleteBoardReducer(builder);
    createUpdateBoardReducer(builder);
    createCopyTemplateReducer(builder);
  },
});

export const {
  setActiveTemplate,
  resetPressedState,
  setActiveBoard,
  updateBoard,
  favoriteBoard,
} = templatesSlice.actions

export default templatesSlice.reducer
