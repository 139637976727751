import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getDaysByMonth from "../domain/utils/getDaysByMonth";
import splitDaysOnWeeks from "../domain/utils/splitDaysOnWeeks";
import { Day } from "../domain/interfaces/day";
import { Calendar } from "../domain/interfaces/calendar";
import { createFetchTournamentDataReducer } from "./redusers";
import { markGameDays } from "./utils/markGameDays";
import { markPassedDays } from "./utils/markPassedDays";
import { markToday } from "./utils/markToday";
import { markSelectedDay } from "./utils/markSelectedDay";
import { clearSelectedDay } from "./utils/clearSelectedDay";
import { Championship } from "../domain/interfaces/championship";

export type ScheduleSliceState = {
  isLoading: boolean
  isLoaded: boolean
  
  selectedDate: Date
  currentMonth: number
  currentYear: number
  weeks: Day[][]
  calendar: Calendar
  
  championship?: Championship
};

const now = new Date();
const interval = getDaysByMonth({ month: now.getMonth(), year: now.getFullYear() });
const weeks = splitDaysOnWeeks({ interval })

const initialState: ScheduleSliceState = {
  isLoading: false,
  isLoaded: false,
  
  selectedDate: new Date(),
  currentMonth: now.getMonth(),
  currentYear: now.getFullYear(),
  weeks: weeks,
  calendar: {},
};

const slice = createSlice({
  name: 'schedule',
  initialState,
  extraReducers: (builder) => {
    createFetchTournamentDataReducer(builder);
  },
  reducers: {
    setCurrentDate: (state, action: PayloadAction<{ month: number; year?: number }>) => {
      const interval = getDaysByMonth(action.payload);
      state.weeks = splitDaysOnWeeks({ interval });
      state.currentMonth = action.payload.month;
      state.currentYear = action.payload.year ?? state.currentYear;
      markGameDays(state);
      markPassedDays(state);
      markToday(state);
      markSelectedDay(state);
    },
    setSelectedDate: (state, action: PayloadAction<Date>) => {
      clearSelectedDay(state);
      state.selectedDate = action.payload;
      markSelectedDay(state);
    },
  },
});

const scheduleReducer = slice.reducer;
export const {
  setCurrentDate,
  setSelectedDate,
} = slice.actions;
export default scheduleReducer;
