import styled from '@emotion/styled';
import React from 'react';
import Block from '../components/block';
import AppText from '../components/text'
import useGameInfo from "../../hooks/useGameInfo";

interface IProps {
  teamIndex: number
}

function Team(props: IProps) {
  const { teams } = useGameInfo()
  const { teamIndex } = props;
  const team = teamIndex === 0 ? teams?.first : teams?.second;
  
  const headers = (
    <TeamMembers>
      <Text color={'#686A79'} size={'small'} style={{ width: sizes.number }}>Номер</Text>
      <Text color={'#686A79'} size={'small'} style={{ width: sizes.name }}>Имя</Text>
      <Text color={'#686A79'} size={'small'} style={{ width: sizes.goals }}>Гол</Text>
      <Text color={'#686A79'} size={'small'} style={{ width: sizes.yellowCards }}>ЖК</Text>
      <Text color={'#686A79'} size={'small'} style={{ width: sizes.redCards }}>КК</Text>
    </TeamMembers>
  )
  
  const members = team?.members?.map((member) => (
    <TeamMembers key={member.id}>
      <Substrate color={'#9093AA'} style={{ width: sizes.number }}>
        <Text color={'#FFF'} size={'medium'}>{member.number}</Text>
      </Substrate>
      <Text color={'#FFF'} size={'medium'} style={{ width: sizes.name }}>
        {`${member.name} ${member.surname} ${member.middleName}`}
      </Text>
      <Substrate color={'#9093AA'} style={{ width: sizes.goals }}>
        <Text color={'#FFF'} size={'medium'}>{member.qtyGoalsMatch}</Text>
      </Substrate>
      <Substrate color={'#D9B244'} style={{ width: sizes.yellowCards }}>
        <Text color={'#FFF'} size={'medium'}>{member.qtyYellowCardsMatch}</Text>
      </Substrate>
      <Substrate color={'#AB138A'} style={{ width: sizes.redCards }}>
        <Text color={'#FFF'} size={'medium'}>{member.qtyRedCardsMatch}</Text>
      </Substrate>
    </TeamMembers>
  ))
  
  return (
    <Block className={'flex-center flex-column'}>
      <Text color={'#FFF'} size={'large'}>{team?.name}</Text>
      {headers}
      {members}
    </Block>
  );
}

export default Team;

const sizes = {
  number: '15%',
  name: '40%',
  goals: '15%',
  yellowCards: '15%',
  redCards: '15%',
}

const TeamMembers = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  width: 100%;
`

const Text = styled(AppText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Substrate = styled.div<{ color: string }>`
  height: 16px;
  background-color: ${({ color }) => color};
  display: flex;
  justify-content: center;
  align-items: center;
`
