import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { GameInfoSliceState } from "./gameInfo";
import { resetGameInfo, syncGameInfo } from "./actions";
import { Team } from "../../domain/interfaces/gameInfo/state";

type GameInfoReducerBuilder = ActionReducerMapBuilder<GameInfoSliceState>;

function resetTeam(team: Team) {
  team.members = team.members?.map(member => {
    return {
      ...member,
      qtyFoulsMatch: 0,
      qtyGoalsMatch: 0,
      qtyRedCardsMatch: 0,
      qtyYellowCardsMatch: 0,
    }
  })
  return team
}

export function createSyncDataReducer(builder: GameInfoReducerBuilder) {
  builder.addCase(syncGameInfo.pending, (state) => {
    state.isSynchronize = false
    state.isSynchronization = true
  });
  
  builder.addCase(syncGameInfo.fulfilled, (state, action) => {
    state.isSynchronize = true
    state.isSynchronization = false
    
    state.goals = action.payload.goals
    state.fouls = action.payload.fouls
    
    const match = action.payload.gameInfo?.match
    if (match) {
      state.match = {
        ...match,
        date: match.dateInfo.fullDate,
      }
    }
    
    const teams = action.payload.gameInfo?.teams
    if (teams) {
      const first = teams.firstTeam
      const second = teams.secondTeam
      if (!first) {
        console.warn({ 'No first team': teams })
        return;
      }
      if (!second) {
        console.warn({ 'No second team': teams })
        return;
      }
      
      state.teams = {
        first: { ...first },
        second: { ...second },
      }
    }
    
    const organizer = action.payload.gameInfo?.organizer
    if (organizer) {
      state.organizer = { ...organizer }
    }
    
    const championship = action.payload.gameInfo?.championship
    if (championship) {
      state.championship = { ...championship }
    }
    
    console.debug({ event: 'data synchronized' })
  });
  
  builder.addCase(syncGameInfo.rejected, (state) => {
    state.isSynchronize = false
    state.isSynchronization = false
  });
}

export function createResetAllReducer(builder: GameInfoReducerBuilder) {
  builder.addCase(resetGameInfo, (state) => {
    state.isSynchronize = false
    state.isSynchronization = false
    
    state.goals = { first: 0, second: 0 }
    state.fouls = { first: 0, second: 0 }
    
    if (state.teams?.first.members) {
      state.teams.first = resetTeam(state.teams.first)
    }
    if (state.teams?.second.members) {
      state.teams.second = resetTeam(state.teams.second)
    }
    
    state.parsedEventsId = []
  });
}
