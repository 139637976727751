import graphqlResource, { GraphqlResource } from "../../../core/graphqlResource";
import { ChampionshipDatesDTO, ChampionshipDTO } from "../interfaces/dtos";
import { extendedChampionshipsMapper } from "../data-mappers/extended-championships-mapper";

interface MatchDatesRepository {
  data: {
    tournament: {
      championships: ChampionshipDatesDTO[]
    }
  },
  error: Error
}

interface ChampionshipRepository {
  data: {
    championship: ChampionshipDTO
  },
  error: Error
}

class OrganizerRepository {
  constructor(
    private readonly graphqlResource: GraphqlResource,
  ) {}
  
  async fetchMatchDates(tournamentId: number) {
    const query = `
      query Tournament($id: Int!) {
          tournament(id: $id) {
              championships {
                  id
                  matches {
                      id
                      date
                  }
              }
          }
      }
    `
    
    const result = await this.graphqlResource.query<MatchDatesRepository>(query, { id: tournamentId })
    
    if (result.data) {
      return result.data.data.tournament.championships
    }
    
    throw new Error('Tournament not found', { cause: result })
  }
  
  async fetchChampionships(championshipIds: number[]) {
    const query = `
        query Championship($id: Int!) {
            championship(id: $id) {
                name
                matches {
                    id
                    sportType
                    date
                    teams {
                        id
                        name
                        emblem {
                            id
                            externalUrl
                        }
                    }
                    teamMatches {
                        team {
                            id
                        }
                        score
                    }
                    round {
                        stage {
                            id
                        }
                    }
                }
                id
                settings {
                    id
                    name
                    value
                }
                tournament {
                    id
                    address {
                        city
                    }
                }
            }
        }
    `
    
    const promises = championshipIds.map(id => this.graphqlResource.query<ChampionshipRepository>(query, { id }))
    const results = await Promise.all(promises)
    
    const error = results.find(result => !result.data)
    if (error) throw new Error('Tournament not found', { cause: error })
    
    return extendedChampionshipsMapper(results.map(result => result.data.data.championship))
  }
}

const repository = new OrganizerRepository(graphqlResource)
export default repository
