/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from "react";
import UDBreadcrumbs from "../../../ud-ui/components/breadcrumbs";
import { Pages } from "../../../navigation/domain/enums/pages";
import UDMainLayout from "../../../ud-ui/layout/main";
import { useDispatch, useSelector } from "react-redux";
import { selectTemplates } from "../../store/catalog/selectors";
import { useParams } from "react-router-dom";
import { MainBlock } from "./styles";
import useFabric from "../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import { Board } from "../../../core/domain/interfaces/supabase/overlay/entity";
import Header from "../components/catalogCard/header";
import { AppDispatch, history } from "../../../../store/store";
import { addView } from "../../store/catalog/actions";
import { useOrganizer } from "../../../organizer/ui/hooks/useOrganizer";
import {
  CanvasWrapper,
  Container,
  LeftContainer,
  RightContainer,
} from "../../../ud-ui/components/fabric-canvas/view-boards/styles";
import BoardsList from "../../../ud-ui/components/fabric-canvas/view-boards/boards-list/boards-list";
import BoardItem from "../../../ud-ui/components/fabric-canvas/view-boards/boards-list/board-item";

function CatalogCard() {
  const dispatch = useDispatch<AppDispatch>()
  const { info } = useOrganizer()
  const id = useParams()?.id;
  const template = useSelector(selectTemplates).find((t) => t.id.toString() === id);
  const boards = template?.boards || [];
  if (!template) {
    history.push(Pages.TEMPLATE.CATALOG.INDEX)
  }
  
  const breadcrumb = useMemo(() => (<UDBreadcrumbs className="my-10" breadcrumbs={[
    { label: 'Турниры', to: Pages.TOURNEYS.INDEX },
    { label: 'Шаблоны', to: Pages.TEMPLATE.INDEX },
    { label: 'Готовые шаблоны', to: Pages.TEMPLATE.CATALOG.INDEX },
    { label: template?.name ?? 'Неизвестный шаблон', to: Pages.TEMPLATE.CATALOG.builders.card(id ? +id : -1) },
  ]}/>), [id, template?.name]);
  
  const [activeBoard, setActiveBoard] = useState(boards[ 0 ]);
  const { canvas, manager } = useFabric('static', {
    data: { ...template?.resolution },
    style: { borderRadius: 8, position: 'absolute' },
  });
  
  useEffect(() => {
    if (!manager) return
    const resizeCanvas = () => {
      manager.setParentSize()
      manager.normalizedViewport()
    }
    
    resizeCanvas()
    window.addEventListener('resize', resizeCanvas)
    
    return () => {
      window.removeEventListener('resize', resizeCanvas)
    }
  }, [manager])
  
  useEffect(() => {
    if (!manager) return
    manager.loadLocal({ scheme: { objects: activeBoard.objects, version: activeBoard.version }, safeBackground: true })
  }, [activeBoard.objects, activeBoard.version, manager]);
  
  const onClick = (board: Board) => {
    setActiveBoard(board)
  }
  
  useEffect(() => {
    if (!info) return
    dispatch(addView({ templateId: id ? +id : -1, userId: info.id }))
  }, [dispatch, id, info]);
  
  return (
    <UDMainLayout headerContent={breadcrumb}>
      <MainBlock>
        <Container>
          <LeftContainer>
            <BoardsList
              items={
                boards.map((board) => {
                  return (
                    <BoardItem
                      key={board.id}
                      board={board}
                      isEditable={false}
                      onClick={() => onClick(board)}
                      isActive={board.id === activeBoard?.id}
                    />
                  )
                })
              }
              headerText={'Табло'}
            />
          </LeftContainer>
          <RightContainer>
            <Header templateId={id ? +id : -1}/>
            <CanvasWrapper>
              {canvas}
            </CanvasWrapper>
          </RightContainer>
        </Container>
      </MainBlock>
    </UDMainLayout>
  );
}

export default CatalogCard;
