import React, { CSSProperties, useEffect, useMemo } from 'react';
import { Game } from "../../../../../../../games/domain/interfaces/Game";
import { useOrganizer } from "../../../../../../../organizer/ui/hooks/useOrganizer";
import { format } from "date-fns";
import ru from "date-fns/locale/ru";
import { getLastGameResult } from "../requesters/fetchLastGameResult";
import useFabric from "../../../../../../../ud-ui/components/fabric-canvas/hooks/useFabric";
import useReplacer from "../../../../../../../ud-ui/components/fabric-canvas/hooks/useReplacer";
import { Resolution, Scheme } from "../../../../../../../ud-ui/components/fabric-canvas/types";
import { getFouls } from "../../../../../../../stream/domain/utils/getFouls";
import { CanvasWrapper } from "../../../../../../../ud-ui/components/fabric-canvas/view-boards/styles";
import { TeamByMatchId } from "../../../../../../domain/interfaces/TeamByMatchId";

type CanvasProps = {
  game: Game
  teamsPlayers: TeamByMatchId[]
  championship: {
    address?: {
      city?: string
    },
    name?: string
  }
  scheme?: Scheme
  resolution?: Resolution
}

const CanvasComponent = ({ game, championship, scheme, resolution, teamsPlayers }: CanvasProps) => {
  const { info } = useOrganizer()
  const fouls = useMemo(() => getFouls(game.id), [game.id])
  const { canvas, manager } = useFabric("static", {
    data: {
      ...(resolution ?? { width: 1920, height: 1080 }),
      backgroundColor: 'transparent',
    },
    style: canvasStyle,
    resizing: true,
  })
  const replacer = useReplacer(manager)
  const lastResults = useMemo(async () => {
    const results = await getLastGameResult(game.id)
    const firstTeamLastResult = results.find((result) => result.team.id === game?.teams[ 0 ]?.id)?.lastGamesResults ?? []
    const secondTeamLastResult = results.find((result) => result.team.id === game?.teams[ 1 ]?.id)?.lastGamesResults ?? []
    firstTeamLastResult.length = Math.min(firstTeamLastResult.length, 3)
    secondTeamLastResult.length = Math.min(secondTeamLastResult.length, 3)
    return [firstTeamLastResult.map(result => result.result as 'WON' | 'DRAW' | 'LOST'), secondTeamLastResult.map(result => result.result as 'WON' | 'DRAW' | 'LOST')]
  }, [game]);
  
  useEffect(() => {
    if (!info) {
      console.warn({ event: 'info not found' });
      return;
    }
    
    if (!manager) {
      console.warn({ event: 'fabricManager not found' });
      return
    }
    
    const replaceAllPlaceHolders = () => {
      const firstTeam = game.teams[ 0 ]
      const secondTeam = game.teams[ 1 ]
      const city = championship.address?.city ? championship.address?.city.split(' ')[ 1 ] : 'Город'
      if (!firstTeam || !secondTeam) {
        console.warn({ event: 'teams not found', firstTeam, secondTeam });
        return
      }
      if (!firstTeam.logo || !secondTeam.logo) {
        console.warn({ event: 'logo not found', firstTeam, secondTeam });
        return
      }
      if (!info.logoUrl) {
        console.warn({ event: 'logoUrl not found', info });
        return
      }
      
      replacer.replaceImageByIndicator('teamLogo0', firstTeam.logo.url)
      replacer.replaceImageByIndicator('teamLogo1', secondTeam.logo.url)
      replacer.replaceImageByIndicator('organizerLogo', info.logoUrl)
      replacer.replaceTextByIndicator('teamName0', firstTeam.name.toUpperCase())
      replacer.replaceTextByIndicator('teamName1', secondTeam.name.toUpperCase())
      replacer.replaceTextByIndicator('teamScore0', (firstTeam.score ?? 0).toString())
      replacer.replaceTextByIndicator('teamScore1', (secondTeam.score ?? 0).toString())
      replacer.replaceTextByIndicator('city', city)
      replacer.replaceTextByIndicator('championshipName', championship.name ?? 'Не задано')
      replacer.replaceTextByIndicator('startMatchTime', format(new Date(game.date), 'HH:mm'))
      replacer.replaceTextByIndicator('startMatchDate', format(new Date(game.date), 'd MMMM', { locale: ru }))
      replacer.replaceTextByIndicator('matchTime', '00:00')
      fouls.then((fouls) => {
        replacer.replaceTextByIndicator('teamFouls0', fouls.first.toString())
        replacer.replaceTextByIndicator('teamFouls1', fouls.second.toString())
      })
      lastResults.then((results) => {
        replacer.replaceLastResultGame('lastGameResults0', results[ 0 ])
        replacer.replaceLastResultGame('lastGameResults1', results[ 1 ])
      })
      if (teamsPlayers) {
        replacer.replaceTeamComposition(teamsPlayers[ 0 ].players.map((player) => ({
          avatar: player.avatar,
          name: `${player.firstName} ${player.lastName}`,
          number: player.number,
        })), 0)
        replacer.replaceTeamComposition(teamsPlayers[ 1 ].players.map((player) => ({
          avatar: player.avatar,
          name: `${player.firstName} ${player.lastName}`,
          number: player.number,
        })), 1)
      }
    }
    
    if (scheme) {
      const data = { scheme: scheme, safeBackground: true };
      manager.loadLocal(data).then(replaceAllPlaceHolders);
      manager.normalizedViewport()
      manager.setParentSize()
    } else {
      replaceAllPlaceHolders()
      manager.clear(true)
    }
  }, [championship.address?.city, championship.name, fouls, game.date, game.teams, info, lastResults, manager, replacer, scheme, teamsPlayers])
  
  return (
    <CanvasWrapper>
      {canvas}
    </CanvasWrapper>
  )
};

const canvasStyle: CSSProperties = { height: '100%', width: '100%', position: 'absolute' }

export default CanvasComponent;
