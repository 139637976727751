/** @jsxImportSource @emotion/react */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { AppDispatch } from "../../../../../../../store/store";
import { fetchTemplates } from "../../../../../../template-editor/store/templates/actions";
import useTemplates from "../../../../../../template-editor/ui/hooks/useTemplates";
import _ from "lodash";
import { TemplateExtend } from "../../../../../../core/domain/interfaces/supabase/overlay/auxiliary-types";
import { Board } from "../../../../../../core/domain/interfaces/supabase/overlay/entity";
import UDIcon from "../../../../../../ud-ui/components/icon";
import { SyncLoader } from "react-spinners";
import { ChampionshipSettings } from "../../../../../domain/interfaces/ChampionshipSettings";
import { Game } from "../../../../../../games/domain/interfaces/Game";
import {
  Container,
  LeftContainer,
  RightContainer,
} from "../../../../../../ud-ui/components/fabric-canvas/view-boards/styles";
import BoardsList from "../../../../../../ud-ui/components/fabric-canvas/view-boards/boards-list/boards-list";
import BoardItem from "../../../../../../ud-ui/components/fabric-canvas/view-boards/boards-list/board-item";
import UDHeaderViewBoards from "../../../../../../ud-ui/components/fabric-canvas/view-boards/right/UDHeaderViewBoards";
import EmptyButton from "../../../../../../ud-ui/components/emptyButton";
import CanvasComponent from "./components/canvasFabric";
import { TeamByMatchId } from "../../../../../domain/interfaces/TeamByMatchId";
import tourneysRepository from "../../../../../domain/repositories/tourneysRepository";

type TemplateProps = {
  game: Game,
  championship: {
    settings: ChampionshipSettings,
    tournamentId: number
    address?: {
      city?: string
    },
    name: string
  },
}

function Template({ game, championship }: TemplateProps) {
  const dispatch = useDispatch<AppDispatch>()
  const { templates, isLoaded, isLoading } = useTemplates()
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateExtend>()
  const [activeBoard, setActiveBoard] = useState<Board>()
  const [teamsPlayers, setTeamsPlayers] = useState<TeamByMatchId[]>()
  
  useEffect(() => {
    tourneysRepository.fetchTeamPlayersByMatchId(game.id).then((teams) => {
      if (!teams) return
      if (teams[ 0 ].teamId === game.teams[ 0 ]?.id && teams[ 1 ].teamId === game.teams[ 1 ]?.id) {
        setTeamsPlayers(teams)
      } else if (teams[ 0 ].teamId === game.teams[ 1 ]?.id && teams[ 1 ].teamId === game.teams[ 0 ]?.id) {
        setTeamsPlayers(teams.reverse())
      }
    })
  }, [game.id, game.teams])
  
  useEffect(() => {
    if (isLoaded) return
    dispatch(fetchTemplates({ tournamentId: championship.tournamentId }))
  }, [championship.tournamentId, dispatch, isLoaded])
  
  useEffect(() => {
    if (!templates || templates.length <= 0) return;
    const overlayId = championship.settings.overlayId;
    const selectedTemplate = _.find(templates, { id: overlayId }) as TemplateExtend | undefined;
    setSelectedTemplate(selectedTemplate);
  }, [championship.settings.overlayId, templates]);
  
  useEffect(() => {
    if (!selectedTemplate) return
    if (selectedTemplate.boards.length <= 0) return
    setActiveBoard(selectedTemplate.boards[ 0 ])
  }, [selectedTemplate])
  
  const ItemList = useMemo(() => {
    if (!selectedTemplate?.boards) return undefined;
    return selectedTemplate.boards.map((board) => {
      return (
        <BoardItem
          key={board.id}
          board={board}
          isEditable={false}
          onClick={() => setActiveBoard(board)}
          isActive={board.id === activeBoard?.id}
        />
      );
    });
  }, [activeBoard, selectedTemplate?.boards]);
  
  const onClickGetLinkOverlay = () => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      toast.error('Необходимо авторизоваться');
      return;
    }
    
    const encodedToken = window.btoa(token);
    navigator.clipboard.writeText(`${window.location.origin}/cover/${game.id}/?token=${encodedToken}`)
      .then(() => {
        toast.success('Ссылка скопирована');
      })
      .catch(() => {
        toast.error('Не удалось скопировать ссылку');
      });
  }
  
  return (
    <div className={'px-6 h-100'}>
      <Container>
        {(isLoading || !teamsPlayers) ? (
          <div className={'flex-center w-100'} style={{ height: 450 }}><SyncLoader/></div>
        ) : (
          <>
            <LeftContainer>
              <BoardsList
                items={ItemList ?? []}
                headerText={'Табло'}
              />
            </LeftContainer>
            <RightContainer>
              <UDHeaderViewBoards
                mainText={activeBoard?.name}
                buttons={[
                  <EmptyButton onClick={onClickGetLinkOverlay} icon={<UDIcon name="chain"/>}>
                    Скопировать ссылку
                  </EmptyButton>,
                ]}
              />
              <CanvasComponent
                game={game}
                teamsPlayers={teamsPlayers}
                championship={championship}
                resolution={selectedTemplate?.resolution}
                scheme={activeBoard ? { version: activeBoard.version, objects: activeBoard.objects } : undefined}
              />
            </RightContainer>
          </>
        )}
      </Container>
    </div>
  );
}

export default Template;
